import React from "react";

// icon
import { SteamIcon } from "../../assets/icons/SteamIcon";
import { CheckIcon } from "../../assets/icons/CheckIcon";

// hooks
import { useWindowSize } from "../../hooks/use-window-size/use-window-size";
import { useSteamRegistrationForm } from "./use-steam-registration";

// components
import Modal from "../modal/Modal";
import Button from "../button/Button";
import Input from "../input/Input";

// styles
import styles from "./SteamRegistrationModal.module.scss";
import classnames from "classnames";

type SteamRegistrationModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export function SteamRegistrationModal({
  isOpen,
  onClose,
}: SteamRegistrationModalProps) {
  const { width } = useWindowSize();

  const {
    steamRegistrationFormData,
    registrationStep,
    isDisabledSubmitButton,
    handleChangeSteamRegistrationData,
    hanldeSendMessageToEmail,
    hanldeSendCode,
    handleSendTradeLink,
  } = useSteamRegistrationForm(onClose);

  return (
    <Modal
      isOpen={Boolean(isOpen)}
      onClose={onClose}
      variant="cart"
      width={width > 1024 ? "55%" : width > 768 ? "75%" : "97%"}
    >
      <div className={styles.modalContent}>
        <div className={styles.contentWrapper}>
          <SteamIcon color="#F2631B" width={114} height={114} />

          <p className={styles.title}>Registration</p>

          <p className={styles.text}>
            Please, verify your email and add Steam trade link
          </p>

          <div
            className={classnames(styles.inputWrapper, {
              [styles.disabledInput]: registrationStep !== 1,
            })}
          >
            <p>1. Enter your email</p>
            <div className={styles.input}>
              {registrationStep > 1 ? (
                <div className={styles.icon}>
                  <CheckIcon />
                </div>
              ) : null}

              <Input
                name="email"
                value={steamRegistrationFormData.email}
                onChange={handleChangeSteamRegistrationData}
                disabled={registrationStep !== 1}
              />
              <Button
                variant="secondary"
                className={styles.button}
                onClick={hanldeSendMessageToEmail}
              >
                Send code
              </Button>
            </div>
          </div>

          <div
            className={classnames(styles.inputWrapper, {
              [styles.disabledInput]: registrationStep !== 2,
            })}
          >
            <p>Enter code from email</p>
            <div className={styles.input}>
              {registrationStep > 2 ? (
                <div className={styles.icon}>
                  <CheckIcon />
                </div>
              ) : null}
              <Input
                name="code"
                value={steamRegistrationFormData.code}
                onChange={handleChangeSteamRegistrationData}
                disabled={registrationStep !== 2}
                type="number"
              />
              <Button
                variant="secondary"
                className={styles.button}
                onClick={hanldeSendCode}
              >
                Verify
              </Button>
            </div>
          </div>

          <div
            className={classnames(styles.inputWrapper, {
              [styles.disabledInput]: registrationStep !== 3,
            })}
          >
            <p>2. Steam trade link</p>
            <div className={styles.input}>
              <Input
                name="trade_link"
                value={steamRegistrationFormData.trade_link}
                onChange={handleChangeSteamRegistrationData}
                disabled={registrationStep !== 3}
              />
              <Button
                variant="secondary"
                disabled={isDisabledSubmitButton}
                onClick={handleSendTradeLink}
                className={styles.button}
              >
                Save link
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.buttonswWrapper}>
          <Button onClick={onClose}>Close</Button>
        </div>
      </div>
    </Modal>
  );
}
