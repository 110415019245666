import React, { FunctionComponent, useContext, useMemo, useState } from "react";
import { z } from "zod";

// context
import { apiContext } from "../api-provider/ApiProvider";

// consts
import { API_URL_BLOG } from "./BlogProvider.consts";

// schemas
import { blogSchema } from "./BlogProvider.schemas";

// types
import type {
  BlogContext,
  BlogFormType,
  BlogProviderProps,
  BlogType,
} from "./BlogProvider.types";

export const blogContext = React.createContext({} as BlogContext);

export const BlogProvider: FunctionComponent<BlogProviderProps> = (props) => {
  const { api } = useContext(apiContext);

  const { children } = props;

  const [blogsData, setBlogsData] = useState<BlogType[] | null>(null);
  const [blogById, setBlogById] = useState<BlogType | null>(null);

  const getBlogs = async () => {
    try {
      const response = await api(API_URL_BLOG, {}, z.array(blogSchema));

      if (response) {
        setBlogsData(response);
        return response;
      }

      return null;
    } catch (error) {
      throw error;
    }
  };

  const getBlogById = async (blogId: string) => {
    try {
      const response = await api(`${API_URL_BLOG}/${blogId}`, {}, blogSchema);

      if (response) {
        const currentBlog = response;

        setBlogById(currentBlog);
        return currentBlog;
      }

      return null;
    } catch (error) {
      throw error;
    }
  };

  const createBlog = async (blogForm: BlogFormType) => {
    try {
      const orderData = await api(
        API_URL_BLOG,
        {
          method: "POST",
          data: blogForm,
        },
        blogSchema
      );

      return orderData;
    } catch (error) {
      throw error;
    }
  };

  const editBlog = async (blogId: string, blogForm: BlogFormType) => {
    try {
      await api(`${API_URL_BLOG}/${blogId}`, {
        method: "PUT",
        data: blogForm,
      });
    } catch (error) {
      throw error;
    }
  };

  const deleteBlog = async (blogId: string) => {
    try {
      await api(`${API_URL_BLOG}/${blogId}`, { method: "DELETE" });
    } catch (error) {
      throw error;
    }
  };

  const contextValue = useMemo(
    () => ({
      getBlogs,
      getBlogById,
      createBlog,
      editBlog,
      deleteBlog,
      blogsData,
      blogById,
    }),
    [
      getBlogs,
      getBlogById,
      createBlog,
      editBlog,
      deleteBlog,
      blogsData,
      blogById,
    ]
  );

  return (
    <blogContext.Provider value={contextValue}>{children}</blogContext.Provider>
  );
};
