import React, { FunctionComponent, useMemo } from "react";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

// consts
import { TOKEN_NAME } from "./SessionProvider.consts";

// types
import type {
  SessionContext,
  SessionProviderProps,
} from "./SessionProvider.types";

export const sessionContext = React.createContext({} as SessionContext);

export const SessionProvider: FunctionComponent<SessionProviderProps> = (
  props
) => {
  const { children } = props;

  const getSessionCookie = () => {
    const accessToken = Cookies.get(TOKEN_NAME);

    return accessToken;
  };

  const getRoleFromToken = () => {
    const accessToken = getSessionCookie();

    if (accessToken) {
      const decodedToken = jwtDecode(accessToken) as { role: "admin" | "user" };

      return decodedToken.role;
    }

    return null;
  };

  const contextValue = useMemo(
    () => ({
      getSessionCookie,
      getRoleFromToken,
    }),
    [getSessionCookie, getRoleFromToken]
  );

  return (
    <sessionContext.Provider value={contextValue}>
      {children}
    </sessionContext.Provider>
  );
};
