import React, { FunctionComponent, useEffect, useMemo, useState } from "react";

// consts
import { CART } from "./CartProvider.consts";

// types
import type {
  CartContext,
  CartProduct,
  CartProviderProps,
} from "./CartProvider.types";
import type { SkinType } from "../skin-provider/SkinProvider.types";

export const cartContext = React.createContext({} as CartContext);

export const CartProvider: FunctionComponent<CartProviderProps> = (props) => {
  const { children } = props;

  const [cartProducts, setCartProducts] = useState<string[]>([]);
  const [cartCount, setCartCount] = useState(0);
  // const [totalPrice, setTotalPrice] = useState("");

  const getCartProducts = async () => {
    const cartString = localStorage.getItem(CART);

    const cart: string[] = cartString ? JSON.parse(cartString) : [];

    setCartProducts(cart);
    setCartCount(cart.length);
  };

  const addSkinToCart = (skin: SkinType) => {
    const cartString = localStorage.getItem(CART);

    const cart: string[] = cartString ? JSON.parse(cartString) : [];

    if (
      !cart.some((cartSkin) => {
        return cartSkin === skin.id;
      })
    ) {
      const addedCart = [skin.id, ...cart];

      localStorage.setItem(CART, JSON.stringify(addedCart));

      return setCartCount(addedCart.length);
    }

    const currentCart = cart.length ? cart : [...cart, skin.id];

    const currentAmountCartItems = currentCart.length;

    localStorage.setItem(CART, JSON.stringify(currentCart));

    return setCartCount(currentAmountCartItems);
  };

  const clearCart = () => {
    localStorage.setItem(CART, JSON.stringify([]));

    setCartCount(0);
  };

  // const addQuantity = (book: CartProduct) => {
  //   const cartString = localStorage.getItem(CART);

  //   const cart: CartProduct[] = cartString ? JSON.parse(cartString) : [];

  //   const updatedCart: CartProduct[] = cart.map((cartBook) => {
  //     if (cartBook.id === book.id) {
  //       return { ...cartBook, quantity: cartBook.quantity + 1 };
  //     }

  //     return cartBook;
  //   });

  //   localStorage.setItem(CART, JSON.stringify(updatedCart));
  // };

  // const removeQuantity = (book: CartProduct) => {
  //   const cartString = localStorage.getItem(CART);

  //   const cart: CartProduct[] = cartString ? JSON.parse(cartString) : [];

  //   const updatedCart: CartProduct[] = cart.map((cartBook) => {
  //     if (cartBook.id === book.id) {
  //       return { ...cartBook, quantity: cartBook.quantity - 1 };
  //     }

  //     return cartBook;
  //   });

  //   const filteredCart = updatedCart.filter((product) => product.quantity > 0);

  //   localStorage.setItem(CART, JSON.stringify(filteredCart));
  //   return setCartCount(filteredCart.length);
  // };

  const deleteSkinFromCart = (skinId: string) => {
    const cartString = localStorage.getItem(CART);

    const cart: string[] = cartString ? JSON.parse(cartString) : [];

    const updatedCart: string[] = cart.filter(
      (cartSkinId) => cartSkinId !== skinId
    );

    localStorage.setItem(CART, JSON.stringify(updatedCart));
    return setCartCount(updatedCart.length);
  };

  const contextValue = useMemo(
    () => ({
      cartProducts,
      cartCount,
      // totalPrice,
      getCartProducts,
      addSkinToCart,
      // addQuantity,
      clearCart,
      // removeQuantity,
      deleteSkinFromCart,
      setCartCount,
    }),
    [cartProducts, cartCount]
  );

  return (
    <cartContext.Provider value={contextValue}>{children}</cartContext.Provider>
  );
};
