import * as React from "react";

// styles
import styles from "./CustomToast.module.scss";

type CustomToastProps = {
  title?: string;
  message: string;
};

const CustomToast = React.memo(function CustomToastFunction({
  title,
  message,
}: CustomToastProps) {
  return (
    <div className={styles.customToast}>
      {title ? <h4>{title}</h4> : null}

      <p>{message}</p>
    </div>
  );
});

export default CustomToast;
