// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&family=Kaushan+Script&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UsersScreen_formWrapper__8s7zm {
  display: grid;
  gap: 32px;
  width: 50%;
  margin: 0 auto;
}
@media only screen and (max-width: 767px) {
  .UsersScreen_formWrapper__8s7zm {
    width: 100%;
  }
}

.UsersScreen_loader__uLsDl {
  justify-self: center;
}

.UsersScreen_selectWrapper__2IPSR {
  display: grid;
  gap: 24px;
}

.UsersScreen_buttonWrapper__Ea2S0 {
  justify-self: center;
}`, "",{"version":3,"sources":["webpack://./src/side/admin/users-screen/UsersScreen.module.scss","webpack://./src/assets/scss/mixins.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,SAAA;EAEA,UAAA;EAEA,cAAA;AAFF;ACLE;EDCF;IASI,WAAA;EADF;AACF;;AAIA;EACE,oBAAA;AADF;;AAIA;EACE,aAAA;EACA,SAAA;AADF;;AAIA;EACE,oBAAA;AADF","sourcesContent":["@use \"../../../assets/scss/main.scss\" as *;\n\n.formWrapper {\n  display: grid;\n  gap: 32px;\n\n  width: 50%;\n\n  margin: 0 auto;\n\n  @include ifmobile {\n    width: 100%;\n  }\n}\n\n.loader {\n  justify-self: center;\n}\n\n.selectWrapper {\n  display: grid;\n  gap: 24px;\n}\n\n.buttonWrapper {\n  justify-self: center;\n}\n","@mixin ifmobile {\n  @media only screen and (max-width: 767px) {\n    @content;\n  }\n}\n\n@mixin iftablet {\n  @media only screen and (max-width: 1023px) {\n    @content;\n  }\n}\n\n@mixin ifdesktopM {\n  @media only screen and (max-width: 1439px) {\n    @content;\n  }\n}\n\n@mixin ifdesktopL {\n  @media only screen and (max-width: 1919px) {\n    @content;\n  }\n}\n\n@mixin ifdesktopXL {\n  @media only screen and (min-width: 1920px) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formWrapper": `UsersScreen_formWrapper__8s7zm`,
	"loader": `UsersScreen_loader__uLsDl`,
	"selectWrapper": `UsersScreen_selectWrapper__2IPSR`,
	"buttonWrapper": `UsersScreen_buttonWrapper__Ea2S0`
};
export default ___CSS_LOADER_EXPORT___;
