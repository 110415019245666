import React from "react";

// styles
import styles from "../General.module.scss";

export function CookieScreen() {
  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <div className={styles.header}>
          <h4>COOKIE POLICY</h4>
          <p>(“Policy”)</p>
          <p>16.10.2024</p>
          <p>
            Your access and use of the Services constitutes your agreement to be
            bound by these Policy. You understand and agree that the Service is
            provided on an AS IS and AS AVAILABLE basis as amended from time to
            time.
          </p>
        </div>

        <h5>What are cookies</h5>
        <p>
          Cookies is a term used to refer to files stored directly on the user’s
          PC. Such files can be accessed by the client PC and the web server to
          store data which may include Personal Data. Such files can be accessed
          by the client’s PC and the web server to store data, which may include
          personal data unique to the client/website. You can disable Cookies at
          any time by selecting this option on the website.
        </p>

        <h5>Types of Cookies We Use</h5>
        <p>
          Cookies can be used by us/third-party service providers to collect
          data on the site about your visits/visits by other users. This data
          may include: IP addresses, domain names, the pages they visit, and
          browser types. Such information is used to improve the functionality
          of the site, for promotional purposes to monitor the effectiveness
          using our web properties. Cookies are both temporary and permanent.
        </p>
        <p>Our website uses the following tools and cookies:</p>
        <p>
          1) Cookies are used to gather site metrics. Such metrics include how
          often people visit the site, how long the site stays open, which pages
          are viewed, what time of day they access more often, and how often
          users find out about notifications.
        </p>
        <p>
          2) Required Cookies: such cookies are necessary for the correct work
          of the site. Cookies are enabled in most browsers by default. You can
          also change the current settings in your browser. For example, you
          have the options: automatically accept cookies, inform you before
          getting a cookie by using the “help” feature on your browser, and
          automatically deny cookies.
        </p>
        <p>
          If you use our site with cookies not disabled in your browser, you
          accept that we use cookies. Also keep in mind that refusing to use
          cookies in your browser may prevent you from being able to use some of
          the features of our website.
        </p>

        <h5>Why does CS EMPORIUM LTD use cookies</h5>
        <p>
          Our company is constantly improving its services using metrics
          gathered by cookies and related technologies, including browser
          cookies, web beacons, pixel tags, local shared objects. We use cookies
          when you use your browser to access our site. Please keep in mind that{" "}
          <strong>CS EMPORIUM LTD</strong> reserves the right to use other
          methods of data collection in case of introduction of different
          technologies.
        </p>

        <h5>Changes to this Policy</h5>
        <p>
          Company will notify you of any updates by posting the new Policy on
          our website with an updated effective date. In the event of
          significant changes to this Policy, our company will take reasonable
          efforts to notify users of the changes.
        </p>
        <h5>Questions</h5>
        <p>
          If you have any questions about the use of cookies, you can directly
          ask{" "}
          <a href="mailto:support@cs-emporium.com">support@cs-emporium.com</a>
        </p>
      </div>
    </div>
  );
}
