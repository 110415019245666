import { useLocation, useNavigate } from "react-router-dom";

// hooks
import { useCart } from "./use-cart";

// compoents
import Spinner from "../../../components/spinner/Spinner";
import { CartProduct } from "./cart-product/CartProduct";
import { OrderSummary } from "./order-summary/OrderSummary";
import Button from "../../../components/button/Button";

// consts
import { PATHS } from "../../../route/route.controls";

// styles
import styles from "./Cart.screen.module.scss";

export function CartScreen() {
  const { pathname } = useLocation();

  const isCurrentPage = pathname === PATHS.cart;

  const { isCartLoading, filteredCart, totalPrice } = useCart(isCurrentPage);

  const navigate = useNavigate();

  const skinIds = filteredCart.map((skin) => skin.id);

  return (
    <section className={styles.cartSection}>
      <div className={styles.contentWrapper}>
        <h3>My Cart</h3>

        {isCartLoading ? (
          <div className={styles.loader}>
            <Spinner color="white" size="large" />
          </div>
        ) : filteredCart.length ? (
          <div className={styles.cartWrapper}>
            <div className={styles.cartProducts}>
              {filteredCart.map((skin) => (
                <CartProduct product={skin} key={skin.id} />
              ))}
            </div>

            <OrderSummary
              total={totalPrice}
              items={filteredCart.length}
              skinIds={skinIds}
            />
          </div>
        ) : (
          <div className={styles.emptyCart}>
            <h3>Your shopping cart is empty</h3>
            <p>But you can choose something for yourself</p>
            <div>
              <Button onClick={() => navigate(PATHS.index)} width="auto">
                Go shopping
              </Button>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
