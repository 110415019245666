import React, { FunctionComponent, useContext, useMemo, useState } from "react";

// context
import { apiContext } from "../api-provider/ApiProvider";

// consts
import {
  API_URL_PAYMENT,
  API_URL_PAYMENT_REFILL,
} from "./PaymentProvider.consts";

// schemas
import {
  allPaymentsSchema,
  paymentSchema,
  refillSchema,
} from "./PaymentProvider.schemas";

// types
import type {
  PaymentContext,
  PaymentFormType,
  PaymentProviderProps,
  PaymentType,
} from "./PaymentProvider.types";

export const paymentContext = React.createContext({} as PaymentContext);

export const PaymentProvider: FunctionComponent<PaymentProviderProps> = (
  props
) => {
  const { api } = useContext(apiContext);

  const { children } = props;

  const [paymentsData, setPaymentsData] = useState<PaymentType[] | null>(null);

  const getPayments = async () => {
    try {
      const response = await api(API_URL_PAYMENT, {}, allPaymentsSchema);

      if (response) {
        const allPayments = response.data;

        setPaymentsData(allPayments);
        return allPayments;
      }

      return null;
    } catch (error) {
      throw error;
    }
  };

  const refillPayment = async (amount: number) => {
    try {
      const payment = await api(
        API_URL_PAYMENT_REFILL,
        { method: "POST", data: { amount } },
        refillSchema
      );

      if (payment) {
        window.location.href = payment.url;
      }
    } catch (error) {
      throw error;
    }
  };

  const refillPaymentConfirm = async (formData: PaymentFormType) => {
    try {
      await api(
        API_URL_PAYMENT_REFILL,
        { method: "POST", data: formData },
        paymentSchema
      );
    } catch (error) {
      throw error;
    }
  };

  const contextValue = useMemo(
    () => ({
      getPayments,
      refillPayment,
      refillPaymentConfirm,
      paymentsData,
    }),
    [getPayments, refillPayment, refillPaymentConfirm, paymentsData]
  );

  return (
    <paymentContext.Provider value={contextValue}>
      {children}
    </paymentContext.Provider>
  );
};
