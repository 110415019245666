import classnames from "classnames";
import React, {
  ChangeEvent,
  InputHTMLAttributes,
  ReactNode,
  Ref,
  forwardRef,
  memo,
  useCallback,
  useId,
  useMemo,
  useState,
} from "react";

// phone-input
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import "react-phone-number-input/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";

// types
import { ValidationType } from "./Input.types";

// icons
import { OpenEyeIcon } from "../../assets/icons/OpenEyeIcon";
import { CloseEyeIcon } from "../../assets/icons/CloseEyeIcon";
import { SearchIcon } from "../../assets/icons/SearchIcon";

// styles
import styles from "./Input.module.scss";

type PhoneOnChange = (value?: any) => void;
type DefaultOnChange = (e: React.ChangeEvent<HTMLInputElement>) => void;

type InputProps<Name> = {
  label?: ReactNode;
  className?: string;
  prefix?: ReactNode;
  suffix?: ReactNode;
  inputClassName?: string;
  wrapperClassName?: string;
  validation?: ValidationType;
  tip?: ReactNode;
  name?: Name;
  variant?: "default" | "search" | "admin" | "footer" | "secondary";
  endIconProps?:
    | string
    | number
    | boolean
    | React.ReactFragment
    | JSX.Element
    | null;
  onChange?: DefaultOnChange | PhoneOnChange;
} & Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "name" | "onChange" | "prefix" | "size"
>;

function Input<Name extends string>(
  {
    validation,
    label,
    variant = "default",
    className,
    type,
    prefix,
    inputClassName,
    wrapperClassName,
    id,
    tip,
    endIconProps,
    onChange,
    name,
    suffix,
    ...props
  }: InputProps<Name>,
  ref: Ref<HTMLInputElement>
) {
  const [inputType, setType] = useState(type);
  const inputId = useId();

  const onPasswordIconClick = useCallback(
    () => setType(inputType === "text" ? "password" : "text"),
    [inputType]
  );

  const endIcon = useMemo(() => {
    if (suffix !== undefined) {
      return suffix;
    }
    if (type === "password") {
      return inputType === "text" ? (
        <OpenEyeIcon
          className={classnames(styles.endIcon, styles.pointer)}
          onClick={onPasswordIconClick}
          color="#000"
        />
      ) : (
        <CloseEyeIcon
          className={classnames(styles.endIcon, styles.pointer)}
          onClick={onPasswordIconClick}
          color="#000"
        />
      );
    }
    return null;
  }, [inputType, type, onPasswordIconClick, suffix]);

  if (type === "phone") {
    return (
      <PhoneInput
        {...props}
        placeholder={props.placeholder}
        flags={flags}
        value={props.value as string}
        onChange={onChange as PhoneOnChange}
        className={classnames(styles.phoneInput, {
          [styles.error]: props.value
            ? !isValidPhoneNumber(props.value as string)
            : null,
        })}
      />
    );
  }

  return (
    <div className={classnames(styles.wrapper, wrapperClassName)}>
      <div className={styles.inputWrapper}>
        {<span className={styles.startIcon}>{prefix}</span>}
        <input
          {...props}
          name={name}
          onChange={onChange}
          id={id || inputId}
          ref={ref}
          type={inputType}
          className={classnames(
            styles.input,
            styles[variant],
            className,
            inputClassName,
            {
              [styles.error]: validation?.error,
              [styles.withEndIcon]: endIcon,
              [styles.withStartIcon]: prefix,
            }
          )}
        />
        {variant === "search" ? (
          <SearchIcon className={styles.startIcon} color="#9b9b9b" />
        ) : null}
        {label && (
          <label
            htmlFor={id || inputId}
            className={classnames(styles.label, {
              [styles.withoutValue]: !props.value && !props.defaultValue,
              [styles.withValue]: props.value || props.defaultValue,
              [styles.withIcon]: variant === "search",
            })}
          >
            <p className={styles.text}>
              {label}{" "}
              {props?.required ? (
                <sup
                  className={classnames({
                    [styles.errorAsterisk]: validation?.error,
                  })}
                >
                  *
                </sup>
              ) : null}
            </p>
          </label>
        )}
        {endIconProps === undefined ? (
          endIcon
        ) : (
          <div className={styles.endIcon}>{endIconProps}</div>
        )}
      </div>

      {(tip || validation?.message) && (
        <p
          className={classnames(styles.tip, {
            [styles.errorText]: Boolean(validation?.message),
          })}
        >
          {validation?.message || tip}
        </p>
      )}
    </div>
  );
}

export default memo(forwardRef(Input)) as typeof Input;
