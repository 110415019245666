import React, { useCallback } from "react";
import { generatePath, useNavigate } from "react-router-dom";

// icons
import { DeleteIcon } from "../../../../../assets/icons/DeleteIcon";

// consts
import { PATHS } from "../../../../../route/route.controls";

// helpers
import { truncateString } from "../../../../../helpers/truncate-string";

// types
import type { BlogType } from "../../../../../context/blog-provider/BlogProvider.types";

// styles
import styles from "./Article.module.scss";

type ArticleProps = {
  article: BlogType;
  openModal?: (blog: BlogType) => void;
  handleOpenDeletingModal?: (blog: BlogType) => void;
};

export function Article({
  article,
  openModal,
  handleOpenDeletingModal,
}: ArticleProps) {
  const navigate = useNavigate();

  const isModeratorModal = !!openModal;

  const handleOpenCurrentArticle = useCallback(
    (articleId: string) => {
      const generatedLink = generatePath(PATHS.article, {
        articleId,
      });

      navigate(generatedLink);
    },
    [navigate, PATHS]
  );

  return (
    <div
      className={styles.articleContainer}
      onClick={
        isModeratorModal
          ? () => openModal(article)
          : () => handleOpenCurrentArticle(article.id)
      }
    >
      <h4>{article.title}</h4>
      <div
        dangerouslySetInnerHTML={{
          __html: truncateString(article.content || "", 220),
        }}
        className={styles.articleContent}
      />

      {!isModeratorModal ? (
        <div className={styles.buttonWrapper}>
          <p>Read more</p>
        </div>
      ) : null}

      {isModeratorModal && handleOpenDeletingModal ? (
        <div className={styles.icon}>
          <DeleteIcon
            onClick={(e) => {
              e.stopPropagation();
              handleOpenDeletingModal(article);
            }}
          />
        </div>
      ) : null}
    </div>
  );
}
