import classNames from "classnames";
import * as React from "react";

// styles
import styles from "./Checkbox.module.scss";

type Props = {
  defaultChecked?: boolean;
  id?: string | number;
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: React.ReactNode | string;
  className?: string;
  checked?: boolean;
  value: number | string;
  disabled?: boolean;
  required?: boolean;
  checkPosition?: "top" | "center";
  ref?: React.RefObject<HTMLInputElement>;
};

const Checkbox = React.memo(function CheckboxFunction(props: Props) {
  const {
    label = "",
    id = "",
    className = "",
    disabled = false,
    required = false,
    onChange,
    checkPosition = "center",
    ...attrs
  } = props;

  return (
    <div
      className={classNames(
        className,
        styles["custom-checkbox"],
        disabled ? styles.disabled : ""
      )}
    >
      <input
        required={required}
        type="checkbox"
        id={String(id)}
        className={classNames(styles.checkbox)}
        disabled={disabled}
        onChange={onChange}
        {...attrs}
      />
      <label
        htmlFor={String(id)}
        className={classNames(
          styles.label,
          checkPosition === "top" ? styles["checkbox-input-top"] : ""
        )}
      >
        {label}
      </label>
    </div>
  );
});

export default Checkbox;
