import React, { FunctionComponent, useMemo } from "react";
import { AxiosError } from "axios";
import { Bounce, ToastContainer, toast } from "react-toastify";

// helpers
import { unknownToError } from "./error";

// components
import CustomToast from "../../components/custom-toast/CustomToast";

// types
import type { ErrorResponse } from "./ErrorProvider.types";

// styles
import "react-toastify/dist/ReactToastify.css";

type ErrorContextType = {
  error: (rawError: unknown, title?: string) => void;
  success: (message: string, title?: string) => void;
  info: (message: string, title?: string) => void;
};

type ErrorProviderProps = {
  children: JSX.Element;
};

export const errorContext = React.createContext({} as ErrorContextType);

export const ErrorProvider: FunctionComponent<ErrorProviderProps> = (props) => {
  const { children } = props;

  const error = (rawError: unknown, title?: string): null => {
    if (rawError instanceof AxiosError) {
      const customError =
        rawError.response?.data || (rawError as ErrorResponse);

      if (Array.isArray(customError.message)) {
        toast.error(
          <CustomToast title={title} message={customError.message[0]} />,
          {
            role: "client",
          }
        );
        return null;
      }

      toast.error(<CustomToast title={title} message={customError.message} />, {
        role: "client",
      });
      return null;
    }

    const error = unknownToError(rawError);
    if (Array.isArray(error.message)) {
      toast.error(<CustomToast title={title} message={error.message[0]} />, {
        role: "client",
      });
      return null;
    }

    toast.error(<CustomToast title={title} message={error.message} />, {
      role: "client",
    });
    return null;
  };

  const success = (message: string, title?: string): void => {
    toast.success(<CustomToast title={title} message={message} />, {
      role: "client",
    });
  };

  const info = (message: string, title?: string): void => {
    toast.info(<CustomToast title={title} message={message} />, {
      role: "client",
    });
  };

  const contextValue = useMemo(() => ({ error, success, info }), []);

  return (
    <errorContext.Provider value={contextValue}>
      {children}
      <ToastContainer
        role="client"
        position="top-right"
        autoClose={false}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
    </errorContext.Provider>
  );
};
