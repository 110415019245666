import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

// components
import App from "./App";

// context
import { ErrorProvider } from "./context/error-provider/ErrorProvider";
import { CurrencyProvider } from "./context/currency-provider/CurrencyProvider";
import { ApiProvider } from "./context/api-provider/ApiProvider";
import { UserProvider } from "./context/user-provider/UserProvider";
import { CartProvider } from "./context/cart-provider/CartProvider";
import { SkinProvider } from "./context/skin-provider/SkinProvider";
import { PaymentProvider } from "./context/payement-provider/PaymentProvider";
import { OrderProvider } from "./context/order-provider/OrderProvider";
import { BlogProvider } from "./context/blog-provider/BlogProvider";
import { SessionProvider } from "./context/session-provider/SessionProvider";

// styles
import "./styles/globals.scss";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <CurrencyProvider>
      <ErrorProvider>
        <SessionProvider>
          <ApiProvider>
            <UserProvider>
              <SkinProvider>
                <CartProvider>
                  <OrderProvider>
                    <BlogProvider>
                      <PaymentProvider>
                        <App />
                      </PaymentProvider>
                    </BlogProvider>
                  </OrderProvider>
                </CartProvider>
              </SkinProvider>
            </UserProvider>
          </ApiProvider>
        </SessionProvider>
      </ErrorProvider>
    </CurrencyProvider>
  </BrowserRouter>
  // </React.StrictMode>
);
