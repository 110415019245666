import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";

// consts
import {
  adminLinksData,
  moderatorLinksData,
  userLinksData,
} from "../header/Header.consts";

// context
import { userContext } from "../../context/user-provider/UserProvider";

// styles
import styles from "./Aside.module.scss";

export function Aside() {
  const { userData } = useContext(userContext);
  const { pathname: currentURL } = useLocation();

  const linksData = userData
    ? userData.role === "admin"
      ? adminLinksData
      : userData.role === "moderator"
      ? moderatorLinksData
      : userLinksData
    : null;

  const isActiveLink = (link: string) => link === currentURL;

  return (
    <aside className={styles.aside}>
      <div className={styles.linksWrapper}>
        {linksData &&
          linksData.map((link, index) => (
            <Link
              to={link.to}
              key={index}
              className={
                isActiveLink(link.to) ? styles.activeLink : styles.link
              }
            >
              {link.title}
            </Link>
          ))}
      </div>
    </aside>
  );
}
