import { z } from "zod";

// schemas
import { skinSchema } from "../skin-provider/SkinProvider.schemas";

export const updatedSkinSchema = skinSchema.extend({
  price: z.number().nullable(),
  price_db: z.number(),
});

export const orderSchema = z.object({
  id: z.string(),
  status: z.enum(["Completed", "Declined", "Error", "Refunded", "Pending"]),
  createdAt: z.string().optional(),
  updatedAt: z.string().optional(),
  skins: z.array(updatedSkinSchema),
  total: z.string(),
});

export const allOrdersSchema = z.object({
  total: z.number(),
  data: z.array(orderSchema),
});
