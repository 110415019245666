import React, { SVGProps } from "react";

const ExitDoorIcon = ({
  className,
  color = "#000",
  width = 24,
  height = 24,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.8789 10.5L7.5 10.5C7.10218 10.5 6.72065 10.658 6.43934 10.9393C6.15804 11.2206 6 11.6022 6 12C6 12.3978 6.15804 12.7793 6.43934 13.0606C6.72065 13.342 7.10218 13.5 7.5 13.5L18.8789 13.5L16.9395 15.4394C16.6595 15.721 16.5026 16.1021 16.5031 16.4991C16.5037 16.8962 16.6616 17.2768 16.9424 17.5576C17.2232 17.8384 17.6038 17.9963 18.0009 17.9969C18.3979 17.9974 18.779 17.8405 19.0606 17.5605L23.5606 13.0605C23.6999 12.9213 23.8104 12.756 23.8858 12.574C23.9612 12.392 24 12.197 24 12C24 11.803 23.9612 11.608 23.8858 11.426C23.8104 11.244 23.6999 11.0787 23.5606 10.9394L19.0606 6.43944C18.9214 6.29953 18.756 6.18845 18.5739 6.11256C18.3918 6.03667 18.1965 5.99746 17.9991 5.99719C17.8018 5.99691 17.6064 6.03558 17.424 6.11096C17.2417 6.18634 17.076 6.29696 16.9365 6.43648C16.797 6.576 16.6864 6.74169 16.611 6.92403C16.5356 7.10638 16.4969 7.30181 16.4972 7.49912C16.4975 7.69644 16.5367 7.89176 16.6126 8.07389C16.6885 8.25603 16.7995 8.4214 16.9395 8.56054L18.8789 10.5Z"
        fill={color}
      />
      <path
        d="M1.5 24L13.5 24C13.697 24.0001 13.8921 23.9614 14.0742 23.886C14.2562 23.8107 14.4216 23.7002 14.5609 23.5609C14.7002 23.4216 14.8107 23.2562 14.886 23.0742C14.9614 22.8921 15.0001 22.697 15 22.5L15 19.5C15 19.1022 14.842 18.7206 14.5607 18.4393C14.2794 18.158 13.8978 18 13.5 18C13.1022 18 12.7206 18.158 12.4393 18.4393C12.158 18.7206 12 19.1022 12 19.5L12 21L3 21L3 3L12 3L12 4.5C12 4.89782 12.158 5.27936 12.4393 5.56066C12.7206 5.84196 13.1022 6 13.5 6C13.8978 6 14.2794 5.84196 14.5607 5.56066C14.842 5.27936 15 4.89783 15 4.5L15 1.5C15.0001 1.30299 14.9614 1.10788 14.886 0.925844C14.8107 0.743805 14.7002 0.578402 14.5609 0.439093C14.4216 0.299782 14.2562 0.189297 14.0742 0.113954C13.8921 0.0386123 13.697 -0.00011074 13.5 1.77621e-06L1.5 7.27141e-07C1.30299 -0.000111824 1.10788 0.0386112 0.925842 0.113953C0.743804 0.189295 0.578401 0.299781 0.439091 0.439091C0.299781 0.5784 0.189295 0.743804 0.113953 0.925843C0.0386106 1.10788 -0.000111503 1.30299 5.96637e-08 1.5L-1.77621e-06 22.5C-0.000113373 22.697 0.0386086 22.8921 0.113951 23.0742C0.189293 23.2562 0.299779 23.4216 0.439089 23.5609C0.578399 23.7002 0.743802 23.8107 0.92584 23.886C1.10788 23.9614 1.30298 24.0001 1.5 24Z"
        fill={color}
      />
    </svg>
  );
};
export { ExitDoorIcon };
