// consts
import { PATHS } from "../../route/route.controls";

export const navigationLinks = [
  {
    title: "Buy Skins",
    to: PATHS.skins,
  },
  {
    title: "Why Us",
    to: PATHS.aboutUs,
  },
  {
    title: "Blog",
    to: PATHS.blog,
  },
];

export const adminLinksData = [
  {
    to: PATHS.profile,
    title: "Profile",
  },
  {
    to: PATHS.orders,
    title: "Orders History",
  },
  {
    to: PATHS.balance,
    title: "Balance",
  },
  {
    to: PATHS.users,
    title: "Users",
  },
  {
    to: PATHS.blogManage,
    title: "Blog Manage",
  },
];

export const moderatorLinksData = [
  {
    to: PATHS.profile,
    title: "Profile",
  },
  {
    to: PATHS.orders,
    title: "Orders History",
  },
  {
    to: PATHS.balance,
    title: "Balance",
  },
  {
    to: PATHS.blogManage,
    title: "Blog Manage",
  },
];

export const userLinksData = [
  {
    to: PATHS.profile,
    title: "Profile",
  },
  {
    to: PATHS.orders,
    title: "Orders History",
  },
  {
    to: PATHS.balance,
    title: "Balance",
  },
];
