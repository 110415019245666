import React from "react";

// styles
import styles from "./PageNotFound.screen.module.scss";

type Props = {};

export function PageNotFound({}: Props) {
  return (
    <div className={styles.container}>
      <h3>404</h3>This page could not be found.
    </div>
  );
}
