import React, { SVGProps } from "react";

const SteamIcon = ({
  className,
  color = "#000",
  width = 25,
  height = 25,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25 12.5C25 19.4052 19.3952 25 12.4798 25C6.74395 25 1.91532 21.1542 0.433468 15.9073L5.23185 17.8881C5.55444 19.506 6.99093 20.7308 8.70464 20.7308C10.6804 20.7308 12.3286 19.0978 12.2429 17.0262L16.502 13.9919C19.128 14.0575 21.3306 11.9304 21.3306 9.27923C21.3306 6.67843 19.2137 4.56653 16.6079 4.56653C14.002 4.56653 11.8851 6.68347 11.8851 9.27923V9.33972L8.90121 13.6593C8.11996 13.6139 7.35383 13.8306 6.70867 14.2692L0 11.497C0.514113 5.06048 5.90222 0 12.4798 0C19.3952 0 25 5.59476 25 12.5ZM7.84778 18.9667L6.31048 18.3317C6.59298 18.9183 7.08075 19.3809 7.68145 19.6321C9.0373 20.1966 10.5948 19.5514 11.1593 18.2006C11.4315 17.5454 11.4365 16.8246 11.1643 16.1694C10.8921 15.5141 10.3831 15 9.72782 14.7278C9.07762 14.4556 8.38206 14.4657 7.76714 14.6976L9.35484 15.3528C10.3528 15.7661 10.8266 16.9103 10.4083 17.9083C9.98992 18.9113 8.84577 19.38 7.84778 18.9667ZM16.6079 12.4194C14.874 12.4194 13.4627 11.0081 13.4627 9.27923C13.4627 7.5504 14.874 6.13911 16.6079 6.13911C18.3417 6.13911 19.753 7.5504 19.753 9.27923C19.753 11.0081 18.3468 12.4194 16.6079 12.4194ZM16.6129 11.6331C17.9183 11.6331 18.9768 10.5746 18.9768 9.27419C18.9768 7.96875 17.9183 6.91532 16.6129 6.91532C15.3075 6.91532 14.249 7.97379 14.249 9.27419C14.254 10.5746 15.3125 11.6331 16.6129 11.6331Z"
        fill={color}
      />
    </svg>
  );
};
export { SteamIcon };
