// images
import empty_hologram_src from "../../../../assets/images/empty_hologram.png";
import crown__hologram_src from "../../../../assets/images/crown_hologram.png";
import human_hologram_src from "../../../../assets/images/human_hologram.png";

export const aboutUsData = [
  {
    title: "Unrivaled Variety",
    description:
      "Dive into a treasure trove of game skins curated from a plethora of popular titles and genres. We pride ourselves on offering an unparalleled selection, ensuring that every gamer can find the perfect skin to suit their individual taste and style. With new additions regularly added to our inventory, there's always something fresh and exciting to discover.",
    img: empty_hologram_src,
  },
  {
    title: "Quality You Can Trust",
    description:
      "At our store, quality is paramount. Each skin in our collection is meticulously crafted by talented artists who are passionate about gaming culture. From intricate details to vibrant colors, every aspect is carefully considered to deliver a product that exceeds expectations. With our commitment to excellence, you can trust that your gaming experience will be elevated to new heights.",
    img: crown__hologram_src,
  },
  {
    title: "Effortless Integration",
    description:
      "Experience gaming like never before with our seamlessly integrated game skins. Designed to effortlessly blend into your favorite titles, our skins ensure a smooth and immersive experience from the moment you hit play. Whether you're customizing characters, weapons, or vehicles, rest assured that our skins will enhance, not hinder, your gaming adventure.",
    img: human_hologram_src,
  },
];
