import React, { SVGProps } from "react";

const DeleteIcon = ({
  className,
  color = "#FF5757",
  width = 24,
  height = 24,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className}
      viewBox="0 0 20 20"
      fill={color}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_293_1810)">
        <path
          d="M10.8333 0H9.16658C8.50354 0 7.86766 0.263392 7.39882 0.732233C6.92998 1.20107 6.66658 1.83696 6.66658 2.5V3.33333H1.66659C1.44557 3.33333 1.23361 3.42113 1.07733 3.57741C0.921049 3.73369 0.833252 3.94565 0.833252 4.16667C0.833252 4.38768 0.921049 4.59964 1.07733 4.75592C1.23361 4.9122 1.44557 5 1.66659 5H2.49992V16.6667C2.49992 17.5507 2.85111 18.3986 3.47623 19.0237C4.10135 19.6488 4.9492 20 5.83325 20H14.1666C15.0506 20 15.8985 19.6488 16.5236 19.0237C17.1487 18.3986 17.4999 17.5507 17.4999 16.6667V5H18.3333C18.5543 5 18.7662 4.9122 18.9225 4.75592C19.0788 4.59964 19.1666 4.38768 19.1666 4.16667C19.1666 3.94565 19.0788 3.73369 18.9225 3.57741C18.7662 3.42113 18.5543 3.33333 18.3333 3.33333H13.3333V2.5C13.3333 1.83696 13.0699 1.20107 12.601 0.732233C12.1322 0.263392 11.4963 0 10.8333 0ZM8.33325 2.5C8.33325 2.27899 8.42105 2.06702 8.57733 1.91074C8.73361 1.75446 8.94557 1.66667 9.16658 1.66667H10.8333C11.0543 1.66667 11.2662 1.75446 11.4225 1.91074C11.5788 2.06702 11.6666 2.27899 11.6666 2.5V3.33333H8.33325V2.5ZM15.8333 16.6667C15.8333 17.1087 15.6577 17.5326 15.3451 17.8452C15.0325 18.1577 14.6086 18.3333 14.1666 18.3333H5.83325C5.39122 18.3333 4.9673 18.1577 4.65474 17.8452C4.34218 17.5326 4.16658 17.1087 4.16658 16.6667V5H15.8333V16.6667Z"
          fill={color}
        />
        <path
          d="M10.0001 7.5C9.77907 7.5 9.56711 7.5878 9.41083 7.74408C9.25455 7.90036 9.16675 8.11232 9.16675 8.33333V15C9.16675 15.221 9.25455 15.433 9.41083 15.5893C9.56711 15.7455 9.77907 15.8333 10.0001 15.8333C10.2211 15.8333 10.4331 15.7455 10.5893 15.5893C10.7456 15.433 10.8334 15.221 10.8334 15V8.33333C10.8334 8.11232 10.7456 7.90036 10.5893 7.74408C10.4331 7.5878 10.2211 7.5 10.0001 7.5Z"
          fill={color}
        />
        <path
          d="M12.5 15C12.5 15.221 12.5878 15.433 12.7441 15.5893C12.9004 15.7455 13.1123 15.8333 13.3333 15.8333C13.5543 15.8333 13.7663 15.7455 13.9226 15.5893C14.0789 15.433 14.1667 15.221 14.1667 15V8.33333C14.1667 8.11232 14.0789 7.90036 13.9226 7.74408C13.7663 7.5878 13.5543 7.5 13.3333 7.5C13.1123 7.5 12.9004 7.5878 12.7441 7.74408C12.5878 7.90036 12.5 8.11232 12.5 8.33333V15Z"
          fill={color}
        />
        <path
          d="M6.66659 7.5C6.44557 7.5 6.23361 7.5878 6.07733 7.74408C5.92105 7.90036 5.83325 8.11232 5.83325 8.33333V15C5.83325 15.221 5.92105 15.433 6.07733 15.5893C6.23361 15.7455 6.44557 15.8333 6.66659 15.8333C6.8876 15.8333 7.09956 15.7455 7.25584 15.5893C7.41212 15.433 7.49992 15.221 7.49992 15V8.33333C7.49992 8.11232 7.41212 7.90036 7.25584 7.74408C7.09956 7.5878 6.8876 7.5 6.66659 7.5Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_293_1810">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export { DeleteIcon };
