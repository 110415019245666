import React, { SVGProps } from "react";

const FilterIcon = ({
  className,
  color = "#FDFDFD",
  width = 22,
  height = 19,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 22 19"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.361 1.626C21.1128 1.086 20.587 0.75 19.9893 0.75H2.01025C1.4125 0.75 0.886753 1.086 0.637753 1.626C0.391003 2.1615 0.476503 2.7735 0.860503 3.222L0.861253 3.22275L7.99975 11.5282V18C7.99975 18.2768 8.152 18.531 8.39575 18.6615C8.50675 18.7208 8.62825 18.75 8.74975 18.75C8.89525 18.75 9.04 18.7073 9.166 18.624L13.666 15.624C13.8745 15.4845 13.9998 15.2505 13.9998 15V11.5282L21.139 3.22275C21.523 2.7735 21.6085 2.1615 21.361 1.626Z"
        fill={color}
      />
    </svg>
  );
};
export { FilterIcon };
