import { useCallback, useContext, useMemo, useState } from "react";

// context
import { errorContext } from "../../../context/error-provider/ErrorProvider";
import { paymentContext } from "../../../context/payement-provider/PaymentProvider";

export function useCreateBalanceForm() {
  const { error } = useContext(errorContext);
  const { refillPayment } = useContext(paymentContext);

  const [balanceFormData, setBalanceFormData] = useState<string>("");

  const handleChangeBalanceData = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      setBalanceFormData(value);
    },
    [setBalanceFormData]
  );

  const onSubmit = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      try {
        await refillPayment(Number(balanceFormData));
      } catch (e) {
        error(e);
      }
    },
    [balanceFormData, refillPayment, error]
  );

  const isDisabledSubmitButton = useMemo(
    () => !balanceFormData,
    [balanceFormData]
  );

  return {
    balanceFormData,
    isDisabledSubmitButton,
    handleChangeBalanceData,
    onSubmit,
  };
}
