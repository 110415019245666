import { useContext, useEffect, useState } from "react";

// context
import { errorContext } from "../../../context/error-provider/ErrorProvider";
import { orderContext } from "../../../context/order-provider/OrderProvider";

export function useHistoryUserFetch() {
  const { error } = useContext(errorContext);
  const { getOrders } = useContext(orderContext);

  const [isHistoryLoading, setIsHistoryLoading] = useState(false);

  const historyFetch = async () => {
    try {
      setIsHistoryLoading(true);

      await getOrders();
    } catch (err) {
      error(err);
    } finally {
      setIsHistoryLoading(false);
    }
  };

  useEffect(() => {
    historyFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isHistoryLoading };
}
