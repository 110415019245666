import React from "react";
import { Outlet } from "react-router-dom";

// components
import { Header } from "../../components/header/Header";
import { Aside } from "../../components/aside/Aside";
import { Footer } from "../../components/footer/Footer";

// styles
import styles from "./UserLayout.module.scss";

export function UserLayout(): JSX.Element {
  return (
    <div className={styles.mainSection}>
      <Header />

      <div className={styles.mainWrapper}>
        <Aside />
        <div className={styles.outletWrapper}>
          <Outlet />
        </div>
      </div>

      <Footer />
    </div>
  );
}
