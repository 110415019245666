import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

// consts
import { PATHS } from "../../../../route/route.controls";

// components
import { Article } from "./article/Article";
import Button from "../../../../components/button/Button";
import Spinner from "../../../../components/spinner/Spinner";

// context
import { blogContext } from "../../../../context/blog-provider/BlogProvider";

// hooks
import { useBlogScreenFetch } from "../../blog-screen/use-blog-screen";

// styles
import styles from "./Blog.module.scss";

export function Blog() {
  const { blogsData } = useContext(blogContext);

  const navigate = useNavigate();

  const { isBlogsLoading } = useBlogScreenFetch();

  return (
    <section className={styles.blogSection}>
      <div className={styles.contentWrapper}>
        {isBlogsLoading ? (
          <Spinner />
        ) : blogsData && blogsData.length ? (
          <>
            <h2>Blog</h2>
            <div className={styles.blogDataWrapper}>
              {blogsData.map((article, index) => (
                <Article article={article} key={index} />
              ))}
            </div>
            <div className={styles.buttonWrapper}>
              <Button
                variant="secondary"
                width="auto"
                onClick={() => navigate(PATHS.blog)}
              >
                Read more articles
              </Button>
            </div>
          </>
        ) : null}
      </div>
    </section>
  );
}
