import { useContext } from "react";

// hooks
import { useWindowSize } from "../../../../../hooks/use-window-size/use-window-size";

// contexts
import { currencyContext } from "../../../../../context/currency-provider/CurrencyProvider";

// icons
import { CartIcon } from "../../../../../assets/icons/CartIcon";

// consts
import {
  skinCategories,
  skinExteriors,
  skinQualities,
  skinQualitiesInfo,
  skinTypes,
} from "../Skins.consts";

// helpers
import { generateSkinImage } from "../../../../../helpers/generateSkinImage";

// components
import Modal from "../../../../../components/modal/Modal";
import Button from "../../../../../components/button/Button";

// types
import type { SkinType } from "../../../../../context/skin-provider/SkinProvider.types";

// styles
import styles from "./SkinModal.module.scss";

type SkinModalProps = {
  skin: SkinType;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export function SkinModal({ skin, isOpen, onClose, onSubmit }: SkinModalProps) {
  const { getCurrencySymbol } = useContext(currencyContext);

  const { width } = useWindowSize();

  return (
    <Modal
      isOpen={Boolean(isOpen)}
      onClose={onClose}
      width={width > 1440 ? "65%" : width > 1024 ? "80%" : "95%"}
      className={styles[skin.quality_id ? skinQualities[skin.quality_id] : ""]}
    >
      <div className={styles.modalContent}>
        <div className={styles.imageWrapper}>
          <img src={generateSkinImage(skin.class_id)} alt={skin.name} />
        </div>

        <div className={styles.info}>
          <p className={styles.title}>{skin.name}</p>
          <div className={styles.descriptionWrapper}>
            <p className={styles.price}>
              {getCurrencySymbol()} {skin.price}
            </p>
            <div className={styles.typeWrapper}>
              <div className={styles.type}>
                {skin.category_id ? (
                  <div className={styles.description}>
                    <p className={styles.descriptionTitle}>Category</p>
                    <p className={styles.descriptionValue}>
                      {skinCategories[skin.category_id]}
                    </p>
                  </div>
                ) : null}

                {skin.quality_id ? (
                  <div className={styles.description}>
                    <p className={styles.descriptionTitle}>Quality</p>
                    <p className={styles.descriptionValue}>
                      {skinQualitiesInfo[skin.quality_id]}
                    </p>
                  </div>
                ) : null}
              </div>
              <div className={styles.type}>
                {skin.exterior_id ? (
                  <div className={styles.description}>
                    <p className={styles.descriptionTitle}>Exterior</p>
                    <p className={styles.descriptionValue}>
                      {skinExteriors[skin.exterior_id]}
                    </p>
                  </div>
                ) : null}

                {skin.type_id ? (
                  <div className={styles.description}>
                    <p className={styles.descriptionTitle}>Type</p>
                    <p className={styles.descriptionValue}>
                      {skinTypes[skin.type_id]}
                    </p>
                  </div>
                ) : null}
              </div>
            </div>
            <div className={styles.buttonWrapper}>
              <Button
                icon={<CartIcon color="#FFB23F" />}
                iconOrientation="start"
                withGradients
                onClick={onSubmit}
              >
                Add to basket
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
