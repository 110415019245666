import { z } from "zod";

export const skinSchema = z.object({
  id: z.string(),
  name: z.string(),
  class_id: z.string(),
  id_bs: z.string().nullable().optional(),
  skin_id: z.number().nullable(),
  quality_id: z.number().nullable(),
  quantity: z.number().optional(),
  exterior_id: z.number().nullable(),
  category_id: z.number().nullable(),
  type_id: z.number().nullable(),
  price: z.number().nullable(),
  price_db: z.string().nullable().optional(),
  isInSale: z.boolean(),
  createdAt: z.string(),
  updatedAt: z.string(),
  deletedAt: z.string().nullable().optional(),
});

export const filtersSkinFormSchema = z.object({
  name: z.string(),
  price_from: z.string(),
  price_to: z.string(),
  exterior: z.string(),
  quality: z.string(),
  category: z.string(),
  type: z.string(),
});

export const allSkinsSchema = z.object({
  total: z.number(),
  data: z.array(skinSchema),
});

export const cartSkinPriceSchema = z.object({
  id: z.string(),
  price: z.number(),
});

export const cartSkinsPriceSchema = z.object({
  skins: z.array(cartSkinPriceSchema),
});
