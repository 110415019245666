import { useContext, useEffect } from "react";

// contexts
import { cartContext } from "../../context/cart-provider/CartProvider";
import { currencyContext } from "../../context/currency-provider/CurrencyProvider";

// hooks
import { useCart } from "../../side/guest/cart-screen/use-cart";

// icons
import { CartIcon } from "../../assets/icons/CartIcon";

// components
import Modal from "../modal/Modal";
import Spinner from "../spinner/Spinner";
import Button from "../button/Button";
import { PreviewCartProduct } from "./preview-cart-product/PreviewCartProduct";

// styles
import styles from "./PreviewCartModal.module.scss";

type PreviewCartModalProps = {
  isOpen: boolean;
  onClose: () => void;
  navigateToCartScreen: () => void;
};

export function PreviewCartModal({
  isOpen,
  onClose,
  navigateToCartScreen,
}: PreviewCartModalProps) {
  const { getCurrencySymbol } = useContext(currencyContext);

  const { isCartLoading, filteredCart, totalPrice } = useCart(isOpen);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className={styles.modal}
      variant="cart"
      width="350px"
    >
      <div className={styles.container}>
        {isCartLoading ? (
          <div className={styles.loader}>
            <Spinner color="white" />
          </div>
        ) : (
          <div>
            {filteredCart.length ? (
              <div>
                <div className={styles.skinInfo}>
                  {filteredCart.map((cartProduct) => (
                    <PreviewCartProduct
                      product={cartProduct}
                      key={cartProduct.id}
                    />
                  ))}
                </div>

                <div className={styles.totalWrapper}>
                  <p>Total</p>
                  <p>
                    {getCurrencySymbol()} {totalPrice}
                  </p>
                </div>
              </div>
            ) : (
              <div className={styles.emptyCart}>
                <CartIcon color="#FCFCFC" width={48} height={48} />
                <h6 className={styles.emptyCartText}>
                  Your shopping cart is empty
                </h6>
              </div>
            )}
          </div>
        )}

        {filteredCart.length ? (
          <div className={styles.buttonWrapper}>
            <Button variant="secondary" onClick={navigateToCartScreen}>
              Buy
            </Button>
          </div>
        ) : null}
      </div>
    </Modal>
  );
}
