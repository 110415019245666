import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ActionMeta } from "react-select";

// context
import { errorContext } from "../../../../context/error-provider/ErrorProvider";
import { skinContext } from "../../../../context/skin-provider/SkinProvider";

// consts
import { INIT_FILTERS_SKIN_FORM } from "./Skins.consts";

// types
import type { FiltersSkinFormType } from "../../../../context/skin-provider/SkinProvider.types";

export function useSkinsForm() {
  const { error, success } = useContext(errorContext);
  const { getSkins } = useContext(skinContext);

  const [skinsFormData, setSkinsFormData] = useState<FiltersSkinFormType>(
    INIT_FILTERS_SKIN_FORM
  );
  const [isIncorrectPrice, setIsIncorrectPrice] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(0);

  const handleChangeSkinData = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      setSkinsFormData((prev) => ({ ...prev, [name]: value }));
    },
    [setSkinsFormData, error]
  );

  const handleChangeSelectData = useCallback(
    (newValue: unknown, actionMeta: ActionMeta<unknown>) => {
      // TODO  Fix the react select types and remove this "as"
      const option = newValue as { label: string; value: string };

      setSkinsFormData((prev) => ({
        ...prev,
        [actionMeta.name || ""]: option.value,
      }));
    },
    [setSkinsFormData]
  );

  const handleClearFilters = useCallback(
    async (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      setOffset: React.Dispatch<React.SetStateAction<number>>
    ) => {
      e.preventDefault();

      try {
        setSkinsFormData(INIT_FILTERS_SKIN_FORM);
        setOffset(0);
        setCurrentPage(0);

        await getSkins(INIT_FILTERS_SKIN_FORM, 20, 0);
      } catch (e) {
        error(e);
      }
    },
    [INIT_FILTERS_SKIN_FORM, setSkinsFormData]
  );

  const onSearch = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      try {
        if (skinsFormData) {
          await getSkins(skinsFormData, 20);
        }
      } catch (e) {
        error(e);
      }
    },
    [skinsFormData, success]
  );

  const priceSkinOptions = useMemo(
    () => [
      { label: "1", value: "1" },
      { label: "10", value: "10" },
      { label: "50", value: "50" },
      { label: "100", value: "100" },
      { label: "500", value: "500" },
      { label: "1000", value: "1000" },
      { label: ">2000", value: "2000" },
    ],
    []
  );

  const typeSkinOptions = useMemo(
    () => [
      { label: "Knife", value: "1" },
      { label: "Gloves", value: "2" },
      { label: "Rifle", value: "3" },
      { label: "Sniper Rifle", value: "4" },
      { label: "Pistol", value: "5" },
      { label: "Machinegun", value: "6" },
      { label: "Shotgun", value: "7" },
      { label: "SMG", value: "8" },
      { label: "Sticker", value: "9" },
      { label: "Graffiti", value: "10" },
      { label: "Container", value: "11" },
      { label: "Key", value: "12" },
      { label: "Agent", value: "13" },
      { label: "Music Kit", value: "14" },
      { label: "Tag", value: "15" },
      { label: "Tool", value: "16" },
      { label: "Collectible", value: "17" },
      { label: "Pass", value: "18" },
      { label: "Patch", value: "19" },
      { label: "Gift", value: "20" },
      { label: "Zeus x27", value: "21" },
    ],
    []
  );

  const qualitySkinOptions = useMemo(
    () => [
      { label: "Consimer Grade", value: "1" },
      { label: "Industrial Grade", value: "2" },
      { label: "Mil-Spec Grade", value: "3" },
      { label: "Restricted", value: "4" },
      { label: "Classified", value: "5" },
      { label: "Covert", value: "6" },
      { label: "Contraband", value: "7" },
      { label: "Base Grade", value: "8" },
      { label: "High Grade", value: "9" },
      { label: "Remarkable", value: "10" },
      { label: "Exotic", value: "11" },
      { label: "Extraordinary", value: "12" },
      { label: "Distinguished", value: "13" },
      { label: "Exceptional", value: "14" },
      { label: "Superior", value: "15" },
      { label: "Master", value: "16" },
    ],
    []
  );

  const exteriorSkinOptions = useMemo(
    () => [
      { label: "Factory New", value: "1" },
      { label: "Minimal Wear", value: "2" },
      { label: "Field-Tested", value: "3" },
      { label: "Well-Worn", value: "4" },
      { label: "Battle-Scarred", value: "5" },
      { label: "Not Painted", value: "6" },
    ],
    []
  );

  const categorySkinOptions = useMemo(
    () => [
      { label: "Normal", value: "1" },
      { label: "★", value: "2" },
      { label: "StatTrak™", value: "3" },
      { label: "★ StatTrak™", value: "4" },
      { label: "Souvenir", value: "5" },
    ],
    []
  );

  useEffect(() => {
    if (
      skinsFormData.price_to &&
      Number(skinsFormData.price_from) > Number(skinsFormData.price_to)
    ) {
      setIsIncorrectPrice(true);
    } else {
      setIsIncorrectPrice(false);
    }
  }, [skinsFormData]);

  const isDisabledSubmitButton = useMemo(
    () => isIncorrectPrice,
    [isIncorrectPrice]
  );

  return {
    skinsFormData,
    priceSkinOptions,
    typeSkinOptions,
    qualitySkinOptions,
    categorySkinOptions,
    exteriorSkinOptions,
    isIncorrectPrice,
    isDisabledSubmitButton,
    currentPage,
    onSearch,
    setCurrentPage,
    handleClearFilters,
    handleChangeSkinData,
    handleChangeSelectData,
  };
}

// context
export function useSkinsFetch(searchParametrs: FiltersSkinFormType) {
  const { error } = useContext(errorContext);
  const { getSkins, getRareSkins } = useContext(skinContext);

  const [isSkinLoading, setIsSkinLoading] = useState(false);

  const [itemOffset, setItemOffset] = useState(0);
  const [itemsAmount, setItemsAmount] = useState(20);

  const skinsFetch = async () => {
    try {
      setIsSkinLoading(true);

      await Promise.all([
        getRareSkins(),
        getSkins(searchParametrs, itemsAmount, itemOffset),
      ]);
    } catch (err) {
      error(err);
      console.error(err);
    } finally {
      setIsSkinLoading(false);
    }
  };

  useEffect(() => {
    skinsFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemOffset]);

  return {
    isSkinLoading,
    itemsAmount,
    setItemsAmount,
    setItemOffset,
  };
}
