import { useContext } from "react";
import { useRoutes } from "react-router-dom";

// routes
import { guestRoutes, userRoutes, adminRoutes } from "./route/routes";

// components
import { ScrollToTop } from "./components/scroll-to-top/ScrollToTop";
import Spinner from "./components/spinner/Spinner";

// contexts
import { userContext } from "./context/user-provider/UserProvider";

// styles
import styles from "./App.module.scss";

function App() {
  const { userData, isProfileLoading } = useContext(userContext);
  const role = userData?.role;

  const isAdmin = role === "admin";
  const isUser = role === "user";

  const routes = isAdmin ? adminRoutes : isUser ? userRoutes : guestRoutes;
  const routesMain = useRoutes(routes);

  return (
    <div className={styles.container}>
      {isProfileLoading ? (
        <div className={styles.appLoader}>
          <Spinner />
        </div>
      ) : (
        <>
          {routesMain} <ScrollToTop />
        </>
      )}
    </div>
  );
}

export default App;
