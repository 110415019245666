import { Link } from "react-router-dom";
import { PATHS } from "../../route/route.controls";

export const footerData = [
  {
    title: "Contact us",
    items: [
      <a href="mailto:support@cs-emporium.com">support@cs-emporium.com</a>,
      <a href="tel:+441514570142">+441514570142</a>,
      "9:00 - 18:00 (Mon - Fri)",
    ],
  },

  {
    title: "Policies",
    items: [
      <Link to={PATHS.cookie}>Cookie</Link>,
      <Link to={PATHS.privacyPolicy}>Privacy Policy</Link>,
      <Link to={PATHS.refundPolicy}>Refund Policy</Link>,
      <Link to={PATHS.termsAndConditions}>Terms and conditions</Link>,
    ],
  },
];
