import { format } from "date-fns";

/**
 * Function for formatting a date in the desired format.
 * @param {string} date - A string representing the date in the format "2024-02-01T13:39:15.672Z".
 * @returns {string} - Formatted date as a string.
 */
export const formattedDate = (date: string | undefined): string => {
  if (date) {
    const inputDate = new Date(date);
    return format(inputDate, "EEEE, d MMMM yyyy, h:mm a");
  }

  return "";
};
