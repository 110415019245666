import React from "react";

type CloseIconProps = {
  className?: string;
  color?: string;
  width?: number;
  height?: number;
};

const CloseIcon = ({
  className,
  color = "#000",
  width = 24,
  height = 24,
}: CloseIconProps) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill={color}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5074 18.1219L14.3855 12L20.5074 5.87813C21.1684 5.21719 21.1684 4.15313 20.5074 3.49219C19.8465 2.83126 18.7824 2.83126 18.1215 3.49219L11.9996 9.61407L5.87773 3.49219C5.2168 2.83126 4.15273 2.83126 3.4918 3.49219C2.83086 4.15313 2.83086 5.21719 3.4918 5.87813L9.61367 12L3.4918 18.1219C2.83086 18.7828 2.83086 19.8469 3.4918 20.5078C4.15273 21.1688 5.2168 21.1688 5.87773 20.5078L11.9996 14.3859L18.1215 20.5078C18.7824 21.1688 19.8465 21.1688 20.5074 20.5078C21.1637 19.8469 21.1637 18.7781 20.5074 18.1219Z"
        fill={color}
      />
    </svg>
  );
};
export { CloseIcon };
