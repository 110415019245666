import { SVGProps } from "react";

const SearchIcon = ({
  className,
  color = "#111726",
  width = 24,
  height = 24,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_568_125)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3ZM1.5 10C1.5 5.30558 5.30558 1.5 10 1.5C14.6944 1.5 18.5 5.30558 18.5 10C18.5 12.0712 17.7592 13.9694 16.5281 15.444L21.306 21.1925C21.3109 21.1984 21.3157 21.2044 21.3205 21.2104C21.6496 21.6321 21.798 22.1672 21.7329 22.6982C21.6679 23.2292 21.3948 23.7127 20.9736 24.0425L20.5113 23.452L20.9729 24.043C20.764 24.2062 20.5251 24.3266 20.2696 24.3973C20.0142 24.4681 19.7474 24.4879 19.4843 24.4555C19.2212 24.4232 18.9671 24.3393 18.7365 24.2087C18.5091 24.08 18.3089 23.9083 18.1471 23.7034L14.9528 19.8323C14.6891 19.5128 14.7344 19.0401 15.0539 18.7765C15.3734 18.5128 15.8461 18.5581 16.1097 18.8776L19.3097 22.7556L19.3224 22.7714C19.3643 22.825 19.4163 22.8699 19.4755 22.9034C19.5347 22.9369 19.5999 22.9584 19.6675 22.9668C19.735 22.9751 19.8035 22.97 19.8691 22.9518C19.9345 22.9337 19.9958 22.9029 20.0493 22.8611C20.1573 22.7763 20.2274 22.6522 20.2441 22.5159C20.2604 22.3823 20.2243 22.2478 20.1435 22.1405L15.4644 16.511C13.9869 17.7523 12.0808 18.5 10 18.5C5.30558 18.5 1.5 14.6944 1.5 10ZM9.89279 4.98103C9.91271 5.18718 9.76174 5.37044 9.55559 5.39035C8.49086 5.49321 7.49452 5.96134 6.73569 6.71528C5.97686 7.46922 5.5023 8.46251 5.39255 9.52656C5.3713 9.73258 5.18707 9.88236 4.98106 9.86111C4.77504 9.83986 4.62526 9.65563 4.64651 9.44962C4.77402 8.21332 5.3254 7.05923 6.20707 6.18324C7.08874 5.30725 8.24637 4.76334 9.48347 4.64383C9.68962 4.62391 9.87288 4.77488 9.89279 4.98103Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_568_125">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export { SearchIcon };
