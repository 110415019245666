import React, { useCallback, useContext, useMemo, useRef } from "react";
import { Editor as TinymceEditor } from "@tinymce/tinymce-react";

// consts
import { API_KEY, EDITOR_INIT_SETTINGS } from "./editor.const";

// context
import { errorContext } from "../../context/error-provider/ErrorProvider";

export type TinymceEditorProps = {
  model: string;
  deleteImage?: (image: { id: number; main: number; url: string }) => void;
  onModelChange: (text: string) => void;
};

function Editor({ model, onModelChange }: TinymceEditorProps) {
  const { error } = useContext(errorContext);

  const inputFileRef = useRef<HTMLInputElement>(null);

  const handleFilePicker = useCallback(
    (callback: (value: string) => void) => {
      if (inputFileRef.current) {
        inputFileRef.current.click();
        inputFileRef.current.onchange = (event: Event) => {
          const target = event.target as HTMLInputElement;
          const file = target.files?.[0];
          if (file) {
            const reader = new FileReader();
            reader.onload = () => {
              const dataURL = reader.result as string;
              callback(dataURL);
            };
            reader.onerror = (e) => {
              console.error("Error occurred while reading the file:", e);
              error("Error occurred while reading the file");
            };
            reader.readAsDataURL(file);
          }
        };
      }
    },
    [inputFileRef]
  );

  const editorInitData = useMemo(() => {
    return {
      ...EDITOR_INIT_SETTINGS,
      file_picker_callback: handleFilePicker,
    };
  }, [EDITOR_INIT_SETTINGS, handleFilePicker]);

  return (
    <div>
      <input type="file" ref={inputFileRef} style={{ display: "none" }} />
      <TinymceEditor
        apiKey={API_KEY}
        onEditorChange={(text) => onModelChange(text)}
        value={model}
        init={editorInitData}
      />
    </div>
  );
}

export default React.memo(Editor);
