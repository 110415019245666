// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&family=Kaushan+Script&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AboutUsItem_aboutUsItem__BE4Ct {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.AboutUsItem_aboutUsItem__BE4Ct img {
  margin-bottom: 8px;
}
.AboutUsItem_aboutUsItem__BE4Ct h4 {
  font-size: 24px;
  font-weight: 700;
  line-height: 38.09px;
  text-align: center;
}
.AboutUsItem_aboutUsItem__BE4Ct p {
  font-size: 16px;
  font-weight: 400;
  line-height: 25.39px;
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/side/guest/main-screen/about-us/about-us-item/AboutUsItem.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AAAF;AAEE;EACE,kBAAA;AAAJ;AAGE;EACE,eAAA;EACA,gBAAA;EACA,oBAAA;EAEA,kBAAA;AAFJ;AAKE;EACE,eAAA;EACA,gBAAA;EACA,oBAAA;EAEA,aAAA;AAJJ","sourcesContent":["@use \"../../../../../assets/scss/main.scss\" as *;\n\n.aboutUsItem {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  img {\n    margin-bottom: 8px;\n  }\n\n  h4 {\n    font-size: 24px;\n    font-weight: 700;\n    line-height: 38.09px;\n\n    text-align: center;\n  }\n\n  p {\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 25.39px;\n\n    padding: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aboutUsItem": `AboutUsItem_aboutUsItem__BE4Ct`
};
export default ___CSS_LOADER_EXPORT___;
