import React, { useContext } from "react";

// components
import { Skin } from "../../skins/skin/Skin";

// context
import { skinContext } from "../../../../../context/skin-provider/SkinProvider";

// styles
import styles from "./CardsSkins.module.scss";

export function CardsSkins() {
  const { rareSkins } = useContext(skinContext);

  return (
    <div className={styles.skinsWrapper}>
      <div className={styles.mainSkin}>
        {rareSkins && rareSkins.length
          ? rareSkins.slice(0, 3).map((skin) => (
              <div className={styles.card} key={skin.id}>
                <Skin skin={skin} type="introduction" />
              </div>
            ))
          : null}
      </div>
    </div>
  );
}
