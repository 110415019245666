import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

// context
import { errorContext } from "../../context/error-provider/ErrorProvider";
import { userContext } from "../../context/user-provider/UserProvider";
import { cartContext } from "../../context/cart-provider/CartProvider";
import { currencyContext } from "../../context/currency-provider/CurrencyProvider";

// consts
import { PATHS } from "../../route/route.controls";

export function useHeaderForm() {
  const { getCurrency, setCurrentCurrency } = useContext(currencyContext);

  const [currencyData, setCurrencyData] = useState<
    { label: string; value: string }[]
  >([]);

  const handleChangeSelectData = useCallback((newValue: unknown) => {
    const option = newValue as { label: string; value: string };

    setCurrencyData([option]);
    setCurrentCurrency(option.value);

    window.location.reload();
  }, []);

  const currencyOptions = useMemo(
    () => [
      { label: "EUR", value: "EUR" },
      { label: "USD", value: "USD" },
      { label: "GBP", value: "GBP" },
      { label: "UAH", value: "UAH" },
    ],
    []
  );

  useEffect(() => {
    const currency = getCurrency();

    setCurrencyData([{ label: currency, value: currency }]);
  }, []);

  return {
    currencyData,
    currencyOptions,
    handleChangeSelectData,
  };
}

export function useHeaderFetch() {
  const { error } = useContext(errorContext);
  const { steamLogIn, logOut } = useContext(userContext);
  const { getCartProducts, clearCart, cartCount } = useContext(cartContext);

  const navigate = useNavigate();

  const [isOpenPreviewCart, setIsOpenPreviewCart] = useState(false);

  const navigateToCartScreen = useCallback(() => {
    setIsOpenPreviewCart(false);
    navigate(PATHS.cart);
  }, [PATHS]);

  const handleSteamLogIn = useCallback(async () => {
    try {
      await steamLogIn();
    } catch (e) {
      error(e);
    }
  }, [steamLogIn]);

  const handleLogOut = useCallback(async () => {
    try {
      await logOut();
      navigate(PATHS.index);

      clearCart();
    } catch (e) {
      error(e);
    }
  }, [steamLogIn]);

  useEffect(() => {
    getCartProducts();
  }, [cartCount]);

  return {
    isOpenPreviewCart,
    cartCount,
    navigateToCartScreen,
    handleSteamLogIn,
    handleLogOut,
    setIsOpenPreviewCart,
  };
}
