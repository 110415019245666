import React, { useContext, useState } from "react";

// components
import { Article } from "../../main-screen/blog/article/Article";
import { Pagination } from "../../../../components/pagination/Pagination";
import Spinner from "../../../../components/spinner/Spinner";

// context
import { blogContext } from "../../../../context/blog-provider/BlogProvider";

// hooks
import { useBlogScreenFetch } from "../use-blog-screen";

// styles
import styles from "./Articles.module.scss";

export function Articles() {
  const { blogsData } = useContext(blogContext);

  const [itemOffset, setItemOffset] = useState(0);

  return (
    <section>
      <div className={styles.contentWrapper}>
        {blogsData && blogsData.length ? (
          <>
            <div className={styles.blogDataWrapper}>
              {blogsData.map((article, index) => (
                <Article article={article} key={index} />
              ))}
            </div>
            <Pagination
              amountOfItems={20}
              length={blogsData.length}
              setItemOffset={setItemOffset}
            />
          </>
        ) : null}
      </div>
    </section>
  );
}
