// types
import type {
  FiltersSkinFormType,
  SkinCategoriesType,
  SkinExteriorType,
  SkinQualitiesInfoType,
  SkinRaritiesType,
  SkinTypesType,
} from "../../../../context/skin-provider/SkinProvider.types";

export const INIT_FILTERS_SKIN_FORM: FiltersSkinFormType = {
  name: "",
  price_from: "",
  price_to: "",
  exterior: "",
  category: "",
  quality: "",
  type: "",
};

export const skinQualities: {
  [key in number]: SkinRaritiesType;
} = {
  1: "common",
  2: "uncommon",
  3: "rare",
  4: "mythical",
  5: "legendary",
  6: "ancient",
  7: "immortal",
  8: "common",
  9: "rare",
  10: "mythical",
  11: "legendary",
  12: "ancient",
  13: "rare",
  14: "mythical",
  15: "legendary",
  16: "ancient",
} as const;

export const skinQualitiesInfo: { [key in number]: SkinQualitiesInfoType } = {
  1: "Consumer Grade",
  2: "Industrial Grade",
  3: "Mil-Spec Grade",
  4: "Restricted",
  5: "Classified",
  6: "Covert",
  7: "Contraband",
  8: "Base Grade",
  9: "High Grade",
  10: "Remarkable",
  11: "Exotic",
  12: "Extraordinary",
  13: "Distinguished",
  14: "Exceptional",
  15: "Superior",
  16: "Master",
};

export const skinTypes: { [key in number]: SkinTypesType } = {
  1: "Knife",
  2: "Gloves",
  3: "Rifle",
  4: "Sniper Rifle",
  5: "Pistol",
  6: "Machinegun",
  7: "Shotgun",
  8: "SMG",
  9: "Sticker",
  10: "Graffiti",
  11: "Container",
  12: "Key",
  13: "Agent",
  14: "Music Kit",
  15: "Tag",
  16: "Tool",
  17: "Collectible",
  18: "Pass",
  19: "Patch",
  20: "Gift",
  21: "Zeus x27",
} as const;

export const skinExteriors: { [key in number]: SkinExteriorType } = {
  1: "Factory New",
  2: "Minimal Wear",
  3: "Field-Tested",
  4: "Well-Worn",
  5: "Battle-Scarred",
  6: "Not Painted",
};

export const skinCategories: { [key in number]: SkinCategoriesType } = {
  1: "Normal",
  2: "★",
  3: "StatTrak™",
  4: "★ StatTrak™",
  5: "Souvenir",
};
