import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// context
import { errorContext } from "../../../context/error-provider/ErrorProvider";
import { blogContext } from "../../../context/blog-provider/BlogProvider";

export function useArticleScreenFetch() {
  const { error } = useContext(errorContext);
  const { getBlogById } = useContext(blogContext);

  const params = useParams();

  const [isArticleLoading, setIsArticleLoading] = useState(false);

  const articleFetch = async () => {
    try {
      if (params.articleId) {
        setIsArticleLoading(true);
        await getBlogById(params.articleId);
      }
    } catch (err) {
      error(err);
    } finally {
      setIsArticleLoading(false);
    }
  };

  useEffect(() => {
    articleFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isArticleLoading,
  };
}
