import React, { useCallback, useContext } from "react";

// components
import Modal from "../../../../components/modal/Modal";
import Input from "../../../../components/input/Input";
import Button from "../../../../components/button/Button";
import Editor from "../../../../components/editor/editor";

// hooks
import { useWindowSize } from "../../../../hooks/use-window-size/use-window-size";
import { useBlogManageForm } from "../use-blog-manage";

// context
import { errorContext } from "../../../../context/error-provider/ErrorProvider";
import { blogContext } from "../../../../context/blog-provider/BlogProvider";

// types
import type { BlogType } from "../../../../context/blog-provider/BlogProvider.types";

// styles
import styles from "./BlogManageModal.module.scss";

type BlogManageModalProps = {
  blog: BlogType | null;
  isOpen: "create" | "edit" | null;
  onClose: () => void;
};

export function BlogManageModal({
  blog,
  isOpen,
  onClose,
}: BlogManageModalProps) {
  const { error } = useContext(errorContext);
  const { getBlogs } = useContext(blogContext);

  const {
    blogManageFormData,
    isDisabledSubmitButton,
    handleChangeBlogManageData,
    handleChangeEditorData,
    onSubmit,
  } = useBlogManageForm(isOpen, blog);

  const { width } = useWindowSize();

  const isCreatingModal = isOpen === "create";

  const modalHeader = isCreatingModal ? "Create new blog" : "Edit blog";

  const handleSubmit = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      try {
        await onSubmit(e);

        await getBlogs();
        onClose();
      } catch (e) {
        error(e);
      }
    },
    [onSubmit, onClose, error]
  );

  const onModelChangeCallback = useCallback(
    (text: string) => handleChangeEditorData(text),
    [handleChangeBlogManageData]
  );

  return (
    <Modal
      isOpen={Boolean(isOpen)}
      onClose={onClose}
      header={modalHeader}
      width={width > 767 ? "75%" : "97%"}
      className={styles.modal}
    >
      <form className={styles.form}>
        <div className={styles.inputWrapper}>
          <Input
            placeholder="Title"
            name="title"
            value={blogManageFormData.title}
            onChange={handleChangeBlogManageData}
          />
          <Editor
            model={blogManageFormData.content}
            onModelChange={onModelChangeCallback}
          />
        </div>

        <div className={styles.buttonsWrapper}>
          <Button
            onClick={onClose}
            variant="outline"
            className={styles.cancelButton}
          >
            Cancel
          </Button>

          <Button
            onClick={handleSubmit}
            type="submit"
            disabled={isDisabledSubmitButton}
          >
            Submit
          </Button>
        </div>
      </form>
    </Modal>
  );
}
