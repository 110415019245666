import { useCallback, useContext, useEffect, useMemo, useState } from "react";

// context
import { errorContext } from "../../../context/error-provider/ErrorProvider";
import { userContext } from "../../../context/user-provider/UserProvider";

// consts
import { INIT_PROFILE_FORM } from "./ProfileScreen.consts";

// types
import type { ProfileFormType } from "../../../context/user-provider/UserProvider.types";

export function useProfileForm() {
  const { error, success } = useContext(errorContext);
  const { updateProfile, sendMessageToEmail, sendEmailCode, userData } =
    useContext(userContext);

  const [profileFormData, setProfileFormData] =
    useState<ProfileFormType>(INIT_PROFILE_FORM);
  const [registrationStep, setRegistrationStep] = useState(1);

  useEffect(() => {
    if (userData) {
      const userFormData = {
        username: userData.username,
        email: userData.email || "",
        activationCode: "",
        tradeUrl: userData.tradeUrl || "",
      };

      setProfileFormData(userFormData);
    }
  }, [userData]);

  const handleChangeProfileData = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;

      setProfileFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    },
    [setProfileFormData, error]
  );

  const onSubmitUsername = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      try {
        if (profileFormData.username) {
          await updateProfile({ username: profileFormData.username });

          success("The username has been successfully edited.");
        }
      } catch (e) {
        error(e);
      }
    },
    [profileFormData, error, success, updateProfile]
  );

  const hanldeSendMessageToEmail = useCallback(async () => {
    try {
      if (profileFormData?.email) {
        await sendMessageToEmail(profileFormData.email);

        setRegistrationStep(2);

        success(
          "The code has been sent to your email. Please check your email."
        );
      }
    } catch (e) {
      error(e);
    }
  }, [
    profileFormData,
    sendMessageToEmail,
    setRegistrationStep,
    success,
    error,
  ]);

  const hanldeSendCode = useCallback(async () => {
    try {
      if (profileFormData?.activationCode) {
        await sendEmailCode(Number(profileFormData.activationCode));

        setRegistrationStep(3);

        success("The email has been successfully changed.");
      }
    } catch (e) {
      error(e);
    }
  }, [sendEmailCode, profileFormData, setRegistrationStep, error]);

  const onSubmitTradeLink = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      try {
        if (profileFormData.tradeUrl) {
          await updateProfile({ tradeUrl: profileFormData.tradeUrl });

          success("The trade link has been successfully changed.");
        }
      } catch (e) {
        error(e);
      }
    },
    [profileFormData, error, success, updateProfile]
  );

  const isDisabledSubmitUsernameButton = useMemo(
    () => !profileFormData.username,
    [profileFormData]
  );

  const isDisabledSendCodeButton = useMemo(
    () => !profileFormData.email,
    [profileFormData]
  );

  const isDisabledSubmitTradeLinkButton = useMemo(
    () => !profileFormData.tradeUrl,
    [profileFormData]
  );

  return {
    profileFormData,
    registrationStep,
    isDisabledSubmitUsernameButton,
    isDisabledSendCodeButton,
    isDisabledSubmitTradeLinkButton,
    handleChangeProfileData,
    hanldeSendMessageToEmail,
    hanldeSendCode,
    onSubmitUsername,
    onSubmitTradeLink,
  };
}

export function useProfileAdminFetch() {
  const { error } = useContext(errorContext);
  const { getProfile } = useContext(userContext);

  const [isProfileLoading, setIsProfileLoading] = useState(false);

  const getProfileData = async () => {
    try {
      setIsProfileLoading(true);

      await getProfile();
    } catch (err) {
      error(err);
    } finally {
      setIsProfileLoading(false);
    }
  };

  useEffect(() => {
    getProfileData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isProfileLoading };
}
