import { useNavigate } from "react-router-dom";

// consts
import { PATHS } from "../../../../route/route.controls";

// components
import Button from "../../../../components/button/Button";
import { CardsSkins } from "./cards-skins/CardsSkins";

// styles
import styles from "./Introduction.module.scss";

export function Introduction() {
  const navigate = useNavigate();

  return (
    <section className={styles.introductionSection}>
      <div className={styles.contentWrapper}>
        <div className={styles.infoWrapper}>
          <p className={styles.comment}>Be stylish, Be unique</p>
          <div className={styles.descriptionWrapper}>
            <div className={styles.description}>
              <h2>CS Emporium: Your Portal to Gaming Style</h2>
              <p>
                Dive into a world where your gaming style takes center stage.
                Skinline offers a vast array of meticulously crafted game skins,
                curated to enhance your gaming experience. Whether you're a
                battle-hardened warrior, a cunning strategist, or a stylish
                adventurer, find your perfect look here and dominate the virtual
                realm in style. Welcome to Skinline, where every gamer's dream
                becomes reality
              </p>
            </div>
            <div className={styles.buttonWrapper}>
              <Button
                width="auto"
                variant="secondary"
                onClick={() => navigate(`/${PATHS.skins}`)}
              >
                Choose Skins
              </Button>
            </div>
          </div>
        </div>

        <CardsSkins />
      </div>
    </section>
  );
}
