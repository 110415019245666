import React, { useCallback, useContext, useState } from "react";
import classnames from "classnames";

// icons
import { CartIcon } from "../../../../../assets/icons/CartIcon";

// helpers
import { generateSkinImage } from "../../../../../helpers/generateSkinImage";
import { truncateString } from "../../../../../helpers/truncate-string";

// hooks
import { useWindowSize } from "../../../../../hooks/use-window-size/use-window-size";

// components
import Button from "../../../../../components/button/Button";
import { SkinModal } from "../skin-modal/SkinModal";

// context
import { cartContext } from "../../../../../context/cart-provider/CartProvider";
import { currencyContext } from "../../../../../context/currency-provider/CurrencyProvider";

// types
import type { SkinType } from "../../../../../context/skin-provider/SkinProvider.types";

// styles
import styles from "./Skin.module.scss";

type SkinProps = {
  skin: SkinType;
  type?: "default" | "introduction" | "top";
  rarity?:
    | "common"
    | "uncommon"
    | "rare"
    | "mythical"
    | "legendary"
    | "ancient"
    | "exceedinglyRare"
    | "immortal";
};

export function Skin({ skin, type = "default", rarity = "common" }: SkinProps) {
  const { addSkinToCart, deleteSkinFromCart, cartProducts } =
    useContext(cartContext);
  const { getCurrencySymbol } = useContext(currencyContext);

  const { width } = useWindowSize();

  const [isOpenSkinModal, setIsOpenSkinModal] = useState(false);

  const isIntroductionSkins = type === "introduction";
  const isTopSkins = type === "top";

  const selectedProduct = cartProducts.find(
    (cartSkinId) => cartSkinId === skin.id
  );

  const handleAddSkinToCart = useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      selectedSkin: SkinType
    ) => {
      e.stopPropagation();

      if (selectedProduct) {
        return deleteSkinFromCart(selectedProduct);
      }

      const bookWithQuantity = { ...selectedSkin, quantity: 1 };

      addSkinToCart(bookWithQuantity);
    },
    [addSkinToCart]
  );

  const buttonText = () => {
    if (selectedProduct) {
      return "In the basket";
    }

    return "Add to basket";
  };

  return (
    <div
      className={classnames(styles.skinWrapper, styles[rarity], {
        [styles.introductionSkinWrapper]: isIntroductionSkins,
        [styles.topSkinWrapper]: isTopSkins,
      })}
      onClick={
        !isIntroductionSkins ? () => setIsOpenSkinModal(true) : undefined
      }
    >
      <div className={styles.imageWrapper}>
        <img src={generateSkinImage(skin.class_id)} alt={skin.name} />
      </div>
      <div className={styles.descriptionWrapper}>
        <div className={styles.description}>
          <p className={styles.title}>{truncateString(skin.name, 25)}</p>
          <p className={styles.price}>
            {getCurrencySymbol()} {skin.price}
          </p>
        </div>
        <Button
          icon={
            <CartIcon
              width={width > 767 ? 26 : isIntroductionSkins ? 10.8 : 16}
              height={width > 767 ? 26 : isIntroductionSkins ? 10.8 : 16}
              color="#FFB23F"
            />
          }
          iconOrientation="start"
          withGradients
          className={classnames(styles.button, {
            [styles.selectedSkin]: !!selectedProduct,
          })}
          onClick={
            !isIntroductionSkins
              ? (e) => handleAddSkinToCart(e, skin)
              : undefined
          }
        >
          {buttonText()}
        </Button>
      </div>

      <SkinModal
        skin={skin}
        isOpen={isOpenSkinModal}
        onClose={() => setIsOpenSkinModal(false)}
        onSubmit={(e) => handleAddSkinToCart(e, skin)}
      />
    </div>
  );
}
