// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&family=Kaushan+Script&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GuestLayout_container__IA8Ua {
  display: flex;
  flex-direction: column;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/layout/guest-layout/GuestLayout.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EAEA,YAAA;AADF","sourcesContent":["@use \"../../assets/scss/main.scss\" as *;\n\n.container {\n  display: flex;\n  flex-direction: column;\n\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `GuestLayout_container__IA8Ua`
};
export default ___CSS_LOADER_EXPORT___;
