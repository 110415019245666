import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import classnames from "classnames";

// icons
// import { PaginationArrowIcon } from "../../assets/icons/PaginationArrow";

// styles
import styles from "./Pagination.module.scss";

type PaginationProps = {
  variant?: "admin" | "user";
  amountOfItems: number;
  length: number;
  currentPage?: number;
  setItemOffset: React.Dispatch<React.SetStateAction<number>>;
  pageOnClick?: () => void;
  setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
};

export function Pagination({
  variant = "user",
  amountOfItems,
  length,
  currentPage,
  setCurrentPage,
  setItemOffset,
  pageOnClick,
}: PaginationProps) {
  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const itemsPerPage = amountOfItems;
  const pageCount = Math.ceil(length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % length;

    setItemOffset(newOffset);
    if (setCurrentPage) {
      setCurrentPage(event.selected);
    }

    if (pageOnClick) {
      pageOnClick();
    }
  };

  const isUser = variant === "user";

  return (
    <div className={styles.container}>
      <ReactPaginate
        breakLabel={<div className={styles.breakLabel}>...</div>}
        onPageChange={handlePageClick}
        pageLinkClassName={classnames(
          styles.link,
          isUser ? styles.pageLink : styles.adminPageLink
        )}
        activeLinkClassName={classnames(
          styles.activeLink,
          isUser ? styles.activeLinkClassName : styles.adminActiveLinkClassName
        )}
        nextLabel={null}
        pageRangeDisplayed={3}
        disabledLinkClassName={styles.disableArrow}
        forcePage={currentPage}
        pageCount={pageCount}
        className={styles.pagination}
        previousLabel={null}
        renderOnZeroPageCount={null}
      />
    </div>
  );
}
