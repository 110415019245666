export const API_KEY = "genu5zkur6fqcm07aitrk5arumzq5mqe2wwe26ek9r55pod9";

export const EDITOR_INIT_SETTINGS = {
  plugins: "image lists paste",
  toolbar:
    "undo redo | formatselect | underline | bold italic | alignleft aligncenter alignright alignjustify | numlist bullist | outdent indent | image",
  skin: "oxide-dark",
  content_css: "dark",
  images_upload_url: "",
  file_picker_types: "image",
};
