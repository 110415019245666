import { useContext } from "react";

// hooks
import { useHistoryUserFetch } from "./use-history";

// helpers
import { formattedDate } from "../../../helpers/format-date";
import { generateSkinImage } from "../../../helpers/generateSkinImage";

// context
import { orderContext } from "../../../context/order-provider/OrderProvider";

// components
import Spinner from "../../../components/spinner/Spinner";

// styles
import styles from "./HistoryScreen.module.scss";
import {
  skinCategories,
  skinExteriors,
  skinQualitiesInfo,
  skinTypes,
} from "../../guest/main-screen/skins/Skins.consts";

export function HistoryScreen() {
  const { ordersData } = useContext(orderContext);

  const { isHistoryLoading } = useHistoryUserFetch();

  return (
    <section className={styles.container}>
      {isHistoryLoading ? (
        <Spinner />
      ) : ordersData && ordersData.length ? (
        <div className={styles.tableWrapper}>
          <div className={styles.tableRowHead}>
            <div>Photo</div>
            <div>Title</div>
            <div>Category</div>
            <div>Quality</div>
            <div>Exterior</div>
            <div>Type</div>
            <div>Price</div>
            <div>Date</div>
          </div>

          {ordersData.map((order) => (
            <div className={styles.tableRow} key={order.id}>
              {order.skins.map((skin) => (
                <div className={styles.tableRowBody} key={skin.id}>
                  <div className={styles.imageWrapper}>
                    <img
                      src={generateSkinImage(skin.class_id)}
                      alt={skin.name}
                    />
                  </div>
                  <div>{skin.name}</div>
                  <div>
                    {skin.category_id ? skinCategories[skin.category_id] : "-"}
                  </div>
                  <div>
                    {skin.quality_id ? skinQualitiesInfo[skin.quality_id] : "-"}
                  </div>
                  <div>
                    {skin.exterior_id ? skinExteriors[skin.exterior_id] : "-"}
                  </div>
                  <div>{skin.type_id ? skinTypes[skin.type_id] : "-"}</div>
                  <div>{skin.price_db}</div>
                  <div>{formattedDate(order.updatedAt)}</div>
                </div>
              ))}
            </div>
          ))}
        </div>
      ) : (
        <h4 className={styles.title}>
          Unfortunately, information about the history is not available.
        </h4>
      )}
    </section>
  );
}
