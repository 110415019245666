// styles
import styles from "../General.module.scss";

export function RefundPolicyScreen() {
  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <div className={styles.header}>
          <h4>Refund Policy</h4>
          <p>23.10.2024</p>
          <p>
            We strive to ensure the highest quality of Services provided on our
            website. If for any reason you are not satisfied with your purchase,
            please review our Refund Policy (hereinafter - “Policy”).
          </p>
          <p>
            However, due to the digital nature of the products and services
            offered, such as game skins, we have a specific Refund Policy
            outlined below.
          </p>
        </div>

        <h5>1. Conditions for Refund</h5>
        <p>
          1.1. The User can request a refund only if the request is made within
          one week of the completion of the order.
        </p>

        <p>
          1.2. Refund requests must be submitted to our support team via email
          at{" "}
          <a href="mailto:support@cs-emporium.com">support@cs-emporium.com</a> ,
          including your order number and the reason for the refund.
        </p>

        <h5>2. Refund Process</h5>
        <p>
          2.1. Refund requests will be reviewed within 5 business days of
          receipt.
        </p>
        <p>
          2.2. If the refund is approved, the funds will be returned to your
          original payment method or your Account within 7-10 business days.
        </p>
        <p>
          2.3. If the refund is denied, you will receive an explanation of the
          reasons for the denial and recommendations for further actions.
        </p>

        <h5>3. Exceptions and Special Conditions</h5>
        <p>3.1. Please note that refunds will not be issued for:</p>

        <ul>
          <li>Purchases made through third-party marketplaces.</li>
          <li>Promotional items, bonuses, or limited-time offers.</li>
          <li>
            Instances where the game skin has already been transferred or used
            within the game.
          </li>
        </ul>
        <p>
          3.2. No Fraudulent Activity: Accounts suspected of fraudulent activity
          or misuse may not be eligible for refunds.
        </p>
        <p>
          3.3. In the event of any technical issues, please contact our support
          team{" "}
          <a href="mailto:support@cs-emporium.com">support@cs-emporium.com</a>{" "}
          for assistance before requesting a refund.
        </p>
        <p>
          3.4. Please keep in mind that <strong>CS EMPORIUM LTD</strong> will
          not refund money if you are not satisfied with the type or style of
          game skin.
        </p>
        <p>
          We provide refunds in case of a mistake: if you received another
          Service for any technical reason. In the event that a game skin is not
          delivered correctly or is faulty (e.g., it does not function as
          intended within the game), customers may be eligible for a replacement
          or a refund. To qualify for a refund or replacement:
        </p>

        <ul>
          <li>The issue must be reported within 48 hours of purchase.</li>
          <li>
            Sufficient proof of the issue must be provided, including
            screenshots or error messages.
          </li>
        </ul>
        <p>
          Our team will assess the claim, and if valid, we will issue a
          replacement or a refund to the original payment method.
        </p>
        <p>
          3.5. At our discretion, if we believe you are abusing our Refund
          Policy, such as where the game skin has already been transferred or
          used within the game or if you’ve previously refunded our Services, we
          reserve the right to deny your refund, restrict you from other future
          refunds, ban your Account, and/or restrict all future use of the
          Services.
        </p>

        <h5>4. Refunds on Unused Credits</h5>
        <p>
          If no product has been purchased using the credits allocated to the
          Cardholder's Account, the Cardholder may be eligible for a refund of
          the unused credits.
        </p>
        <p>
          Customers may be eligible for a refund of unused credits if no game
          skins or other digital products have been purchased. Refund requests
          must meet the following conditions:
        </p>
        <ul>
          <li>
            The credits have not been used to purchase any game skins or digital
            content.
          </li>
          <li>
            The refund request is made within 7 days from the date of credit
            purchase.
          </li>
        </ul>
        <p>
          Refunds for unused credits will be processed to the original payment
          method used during the transaction.
        </p>

        <h5>5. Your Consent</h5>
        <p>
          By using our website, creating a Personal Account (“Account”), making
          a purchase, you hereby consent to our Refund Policy and agree to its
          terms.
        </p>

        <h5>6. Changes to our Refund Policy</h5>
        <p>
          We reserve the right to modify this Refund Policy at any time. Any
          changes to this Policy will be posted on our website. It is your
          responsibility to review this Policy periodically for any updates or
          changes. Continued use of our Services after any modifications
          constitutes your acceptance of the revised Policy. If you do not want
          to agree to this or any updated Refund Policy, you can delete your
          Account.
        </p>
        <h5>Contact Us</h5>
        <p>
          If you have any questions or need further assistance, please contact
          us at{" "}
          <a href="mailto:support@cs-emporium.com">support@cs-emporium.com</a>{" "}
        </p>
        <p>
          For more information, visit our website{" "}
          <a href="https://cs-emporium.com">https://cs-emporium.com</a>
        </p>
      </div>
    </div>
  );
}
