import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

// context
import { apiContext } from "../api-provider/ApiProvider";

// helpers
import { handleErrorWithMessages } from "../error-provider/error";

// consts
import {
  API_URL_AUTH_STEAM,
  API_URL_AUTH_EMAIL,
  API_URL_FEEDBACK,
  API_URL_PROFILE,
  API_URL_USER,
  API_URL_EMAIL_CONFIRM,
  API_URL_TRADE_URL,
  API_URL_AUTH_LOGOUT,
} from "./UserProvider.consts";

// schemas
import {
  allUsersSchema,
  profileSchema,
  steamAuthSchema,
} from "./UserProvider.schemas";

// types
import type {
  FeedbackFormType,
  ProfileType,
  UpdatedProfileFormType,
  UserContext,
  UserProviderProps,
} from "./UserProvider.types";

export const userContext = React.createContext({} as UserContext);

export const UserProvider: FunctionComponent<UserProviderProps> = (props) => {
  const { api } = useContext(apiContext);

  const { children } = props;

  const [users, setUsers] = useState<ProfileType[] | null>(null);
  const [userData, setUserData] = useState<ProfileType | null>(null);

  const [isProfileLoading, setIsProfileLoading] = useState(true);

  const steamLogIn = async () => {
    try {
      const authData = await api(API_URL_AUTH_STEAM, {}, steamAuthSchema);

      if (authData) {
        window.location.href = authData.steamAuthUrl;
      }
    } catch (error) {
      throw error;
    }
  };

  const sendMessageToEmail = async (email: string) => {
    try {
      await api(API_URL_AUTH_EMAIL, {
        method: "POST",
        data: { email },
      });
    } catch (error) {
      throw error;
    }
  };

  const sendEmailCode = async (activationCode: number) => {
    try {
      await api(API_URL_EMAIL_CONFIRM, {
        method: "PUT",
        data: { activationCode },
      });
    } catch (error) {
      throw handleErrorWithMessages(error, {
        "UserNotFoundException: User not found":
          "The code you entered from your email is incorrect. Please check the code and try again.",
        "UnauthorizedException: Invalid credentials":
          "Invalid credentials. Please try again.",
      });
    }
  };

  const sendTradeLink = async (tradeUrl: string) => {
    try {
      const response = await api(API_URL_TRADE_URL, {
        method: "POST",
        data: { tradeUrl },
      });

      if (response && userData) {
        setUserData({ ...userData, tradeUrl });
      }
    } catch (error) {
      throw error;
    }
  };

  const logOut = async () => {
    try {
      await api(API_URL_AUTH_LOGOUT);

      setUserData(null);
    } catch (error) {
      throw error;
    }
  };

  const getProfile = async () => {
    try {
      const userData = await api(API_URL_PROFILE, {}, profileSchema);

      setUserData(userData);
      return userData;
    } catch (error) {
      console.error(error);
    } finally {
      setIsProfileLoading(false);
    }
  };

  const updateProfile = async (
    formData: Omit<UpdatedProfileFormType, "email">
  ) => {
    try {
      if (userData) {
        await api(API_URL_PROFILE, {
          method: "PUT",
          data: formData,
        });
      }

      return null;
    } catch (error) {
      throw error;
    }
  };

  const getUsers = async () => {
    try {
      const allUsers = await api(API_URL_USER, {}, allUsersSchema);

      if (allUsers) {
        setUsers(allUsers.data);
      }
    } catch (error) {
      throw error;
    }
  };

  const updateUserById = async (
    userId: string,
    role: "user" | "admin" | "moderator"
  ) => {
    try {
      await api(`${API_URL_USER}/${userId}`, { method: "PUT", data: { role } });
    } catch (error) {
      throw error;
    }
  };

  const deleteUser = async (userId: string) => {
    try {
      await api(`${API_URL_USER}/${userId}`, { method: "DELETE" });
    } catch (error) {
      throw error;
    }
  };

  const feedback = async (formData: FeedbackFormType) => {
    try {
      await api(API_URL_FEEDBACK, {
        method: "POST",
        data: formData,
      });
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  const contextValue = useMemo(
    () => ({
      users,
      userData,
      isProfileLoading,
      steamLogIn,
      sendMessageToEmail,
      sendEmailCode,
      sendTradeLink,
      logOut,
      getProfile,
      updateProfile,
      getUsers,
      updateUserById,
      deleteUser,
      feedback,
    }),
    [
      users,
      userData,
      isProfileLoading,
      steamLogIn,
      sendMessageToEmail,
      sendEmailCode,
      sendTradeLink,
      logOut,
      getProfile,
      updateProfile,
      getUsers,
      updateUserById,
      deleteUser,
      feedback,
    ]
  );

  return (
    <userContext.Provider value={contextValue}>{children}</userContext.Provider>
  );
};
