// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&family=Kaushan+Script&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BlogRecomendations_contentWrapper__pVV\\+q {
  display: grid;
  gap: 64px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  max-width: 1012px;
  width: 100%;
  padding: 0 15px;
}
.BlogRecomendations_contentWrapper__pVV\\+q h2 {
  text-align: center;
  font-weight: 700;
  line-height: 54.14px;
  letter-spacing: -0.03em;
}
@media only screen and (max-width: 1439px) {
  .BlogRecomendations_contentWrapper__pVV\\+q {
    max-width: 844px;
    box-sizing: border-box;
  }
}
@media only screen and (max-width: 1023px) {
  .BlogRecomendations_contentWrapper__pVV\\+q {
    max-width: 688px;
    gap: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .BlogRecomendations_contentWrapper__pVV\\+q {
    max-width: 500px;
  }
}

.BlogRecomendations_carousel__WFqet {
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/side/guest/blog-screen/blog-recomedations/BlogRecomendations.module.scss","webpack://./src/assets/scss/mixins.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,SAAA;EAEA,sBAAA;EACA,iBAAA;EACA,kBAAA;EAEA,iBAAA;EACA,WAAA;EAEA,eAAA;AAHF;AAKE;EACE,kBAAA;EAEA,gBAAA;EACA,oBAAA;EACA,uBAAA;AAJJ;ACHE;EDXF;IAsBI,gBAAA;IACA,sBAAA;EAJF;AACF;ACfE;EDLF;IA2BI,gBAAA;IACA,SAAA;EAHF;AACF;AC3BE;EDCF;IAgCI,gBAAA;EAFF;AACF;;AAKA;EACE,gBAAA;AAFF","sourcesContent":["@use \"../../../../assets/scss/main.scss\" as *;\n\n.contentWrapper {\n  display: grid;\n  gap: 64px;\n\n  box-sizing: border-box;\n  margin-left: auto;\n  margin-right: auto;\n\n  max-width: calc($main-container-width - 288px);\n  width: 100%;\n\n  padding: 0 15px;\n\n  h2 {\n    text-align: center;\n\n    font-weight: 700;\n    line-height: 54.14px;\n    letter-spacing: -0.03em;\n  }\n\n  @include ifdesktopM {\n    max-width: calc($tablet-container-width - 180px);\n    box-sizing: border-box;\n  }\n\n  @include iftablet {\n    max-width: calc($mobile-container-width - 80px);\n    gap: 24px;\n  }\n\n  @include ifmobile {\n    max-width: 500px;\n  }\n}\n\n.carousel {\n  overflow: hidden;\n}\n","@mixin ifmobile {\n  @media only screen and (max-width: 767px) {\n    @content;\n  }\n}\n\n@mixin iftablet {\n  @media only screen and (max-width: 1023px) {\n    @content;\n  }\n}\n\n@mixin ifdesktopM {\n  @media only screen and (max-width: 1439px) {\n    @content;\n  }\n}\n\n@mixin ifdesktopL {\n  @media only screen and (max-width: 1919px) {\n    @content;\n  }\n}\n\n@mixin ifdesktopXL {\n  @media only screen and (min-width: 1920px) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentWrapper": `BlogRecomendations_contentWrapper__pVV+q`,
	"carousel": `BlogRecomendations_carousel__WFqet`
};
export default ___CSS_LOADER_EXPORT___;
