import { Link, useNavigate } from "react-router-dom";

// images
import visa_image from "../../assets/images/visa_image.png";
import logo_src from "../../assets/images/logo.png";

// consts
import { navigationLinks } from "../header/Header.consts";
import { PATHS } from "../../route/route.controls";

// consts
import { footerData } from "./Footer.consts";

// styles
import styles from "./Footer.module.scss";

export function Footer() {
  const navigate = useNavigate();

  return (
    <footer className={styles.footer}>
      <div className={styles.contentWrapper}>
        <div className={styles.info}>
          <div className={styles.navigation}>
            <div
              className={styles.logoWrapper}
              onClick={() => navigate(PATHS.index)}
            >
              <img src={logo_src} alt="logo" />
            </div>
            <div className={styles.links}>
              {navigationLinks.map((link, index) => (
                <Link to={link.to} key={index} className={styles.link}>
                  {link.title}
                </Link>
              ))}
            </div>
          </div>

          <div className={styles.footerItemWrapper}>
            {footerData.map((footerItem) => (
              <div key={footerItem.title} className={styles.contactWrapper}>
                <p className={styles.title}>{footerItem.title}</p>

                <div className={styles.footerItems}>
                  {footerItem.items.map((item, index) => (
                    <p className={styles.footerItem} key={index}>
                      {item}
                    </p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.credentials}>
          <p>CS Emporium LTD</p>
          <p>
            71-75 Shelton Street, Covent Garden, <br /> London, United Kingdom,
            WC2H 9JQ
          </p>
          <img src={visa_image} alt="visa cards" className={styles.visaImage} />
        </div>
      </div>
    </footer>
  );
}
