// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&family=Kaushan+Script&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BlogManageScreen_spinner__NULTi {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 32px 0;
  height: 100%;
}

.BlogManageScreen_container__4VWcF {
  display: grid;
  gap: 24px;
}

.BlogManageScreen_contentWrapper__xSjEU {
  display: flex;
  flex-direction: column;
  gap: 24px;
}`, "",{"version":3,"sources":["webpack://./src/side/moderator/blog-manage-screen/BlogManageScreen.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EAEA,cAAA;EAEA,YAAA;AAFF;;AAKA;EACE,aAAA;EACA,SAAA;AAFF;;AAKA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAFF","sourcesContent":["@use \"../../../assets/scss/main.scss\" as *;\n\n.spinner {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  margin: 32px 0;\n\n  height: 100%;\n}\n\n.container {\n  display: grid;\n  gap: 24px;\n}\n\n.contentWrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": `BlogManageScreen_spinner__NULTi`,
	"container": `BlogManageScreen_container__4VWcF`,
	"contentWrapper": `BlogManageScreen_contentWrapper__xSjEU`
};
export default ___CSS_LOADER_EXPORT___;
