// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&family=Kaushan+Script&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CardsSkins_skinsWrapper__ksYzg {
  position: relative;
  width: fit-content;
}
@media only screen and (max-width: 1023px) {
  .CardsSkins_skinsWrapper__ksYzg {
    margin-left: 20%;
    margin-bottom: 90px;
    order: -1;
  }
}
@media only screen and (max-width: 767px) {
  .CardsSkins_skinsWrapper__ksYzg {
    margin-left: 30%;
  }
}

.CardsSkins_mainSkin__XUElf {
  position: relative;
  z-index: 1;
}

.CardsSkins_card__8jNuI {
  position: relative;
}

.CardsSkins_card__8jNuI:nth-child(2) {
  position: absolute;
  right: -50%;
  top: 10%;
  transform: rotate(15deg);
  z-index: -1;
}

.CardsSkins_card__8jNuI:nth-child(3) {
  position: absolute;
  right: -95%;
  top: 30%;
  transform: rotate(30deg);
  z-index: -2;
}`, "",{"version":3,"sources":["webpack://./src/side/guest/main-screen/introduction/cards-skins/CardsSkins.module.scss","webpack://./src/assets/scss/mixins.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,kBAAA;AAAF;ACGE;EDLF;IAKI,gBAAA;IACA,mBAAA;IAEA,SAAA;EAAF;AACF;ACVE;EDCF;IAYI,gBAAA;EACF;AACF;;AAEA;EACE,kBAAA;EACA,UAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;EACA,QAAA;EACA,wBAAA;EACA,WAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;EACA,QAAA;EACA,wBAAA;EACA,WAAA;AACF","sourcesContent":["@use \"../../../../../assets/scss/main.scss\" as *;\n\n.skinsWrapper {\n  position: relative;\n  width: fit-content;\n\n  @include iftablet {\n    margin-left: 20%;\n    margin-bottom: 90px;\n\n    order: -1;\n  }\n\n  @include ifmobile {\n    margin-left: 30%;\n  }\n}\n\n.mainSkin {\n  position: relative;\n  z-index: 1;\n}\n\n.card {\n  position: relative;\n}\n\n.card:nth-child(2) {\n  position: absolute;\n  right: -50%;\n  top: 10%;\n  transform: rotate(15deg);\n  z-index: -1;\n}\n\n.card:nth-child(3) {\n  position: absolute;\n  right: -95%;\n  top: 30%;\n  transform: rotate(30deg);\n  z-index: -2;\n}\n","@mixin ifmobile {\n  @media only screen and (max-width: 767px) {\n    @content;\n  }\n}\n\n@mixin iftablet {\n  @media only screen and (max-width: 1023px) {\n    @content;\n  }\n}\n\n@mixin ifdesktopM {\n  @media only screen and (max-width: 1439px) {\n    @content;\n  }\n}\n\n@mixin ifdesktopL {\n  @media only screen and (max-width: 1919px) {\n    @content;\n  }\n}\n\n@mixin ifdesktopXL {\n  @media only screen and (min-width: 1920px) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skinsWrapper": `CardsSkins_skinsWrapper__ksYzg`,
	"mainSkin": `CardsSkins_mainSkin__XUElf`,
	"card": `CardsSkins_card__8jNuI`
};
export default ___CSS_LOADER_EXPORT___;
