import React, {
  ForwardedRef,
  LegacyRef,
  MutableRefObject,
  forwardRef,
} from "react";

// consts
import { aboutUsData } from "./AboutUs.consts";

// components
import { AboutUsItem } from "./about-us-item/AboutUsItem";

// styles
import styles from "./AboutUs.module.scss";

export const AboutUs = forwardRef(function Skins(
  _,
  forwardRef: ForwardedRef<HTMLDivElement>
) {
  return (
    <section className={styles.aboutUsSection} ref={forwardRef}>
      <div className={styles.contentWrapper}>
        <h2>Why us?</h2>

        <div className={styles.aboutUsDataWrapper}>
          {aboutUsData.map((aboutUsItem, index) => (
            <AboutUsItem aboutUsItem={aboutUsItem} key={index} />
          ))}
        </div>
      </div>
    </section>
  );
});
