import { useContext, useEffect, useState } from "react";

// context
import { errorContext } from "../../../context/error-provider/ErrorProvider";
import { skinContext } from "../../../context/skin-provider/SkinProvider";
import { cartContext } from "../../../context/cart-provider/CartProvider";

// types
import type { UpdatedSkinType } from "../../../context/skin-provider/SkinProvider.types";

export function useCart(isOpen: boolean) {
  const { error } = useContext(errorContext);
  const { getSkinById, checkCartSkinPrice } = useContext(skinContext);
  const { cartProducts } = useContext(cartContext);

  const [isCartLoading, setIsCartLoading] = useState(true);

  const [filteredCart, setFilteredCart] = useState<UpdatedSkinType[]>([]);

  const [totalPrice, setTotalPrice] = useState("");

  const fetchCartSkins = async () => {
    try {
      const cartSkins = (await Promise.all(
        cartProducts.map((cartSkinId) => getSkinById(cartSkinId))
      )) as UpdatedSkinType[];

      const validCartSkins = cartSkins.filter((skin) => skin !== null);

      const skinsPrice = await checkCartSkinPrice();

      if (skinsPrice) {
        const skinsWithCorrectPrice = validCartSkins.map((skin) => {
          const matchingCartSkin = skinsPrice.find(
            (cartSkin) => cartSkin.id === skin.id
          );

          if (matchingCartSkin) {
            return { ...skin, price: matchingCartSkin.price };
          }

          return skin;
        });

        const skinsTotal = skinsWithCorrectPrice
          .reduce((acc, currValue) => acc + (currValue?.price || 0), 0)
          .toFixed(2);

        setTotalPrice(skinsTotal);
        setFilteredCart(skinsWithCorrectPrice);
      }
    } catch (e) {
      error(e);
    }
  };

  const getCartSkinPrice = async () => {
    try {
      const skinsPrice = await checkCartSkinPrice();

      if (skinsPrice) {
        setFilteredCart((prev) =>
          prev.map((skin) => {
            const matchingCartSkin = skinsPrice.find(
              (cartSkin) => cartSkin.id === skin.id
            );

            if (matchingCartSkin) {
              return { ...skin, price: matchingCartSkin.price };
            }

            return skin;
          })
        );
      }
    } catch (e) {
      error(e);
    }
  };

  const fetchAllCartData = async () => {
    try {
      setIsCartLoading(true);

      await fetchCartSkins();
    } catch (e) {
      error(e);
    } finally {
      setIsCartLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchAllCartData();

      const intervalId = setInterval(() => {
        getCartSkinPrice();
      }, 15000);

      return () => clearInterval(intervalId);
    }
  }, [cartProducts, isOpen]);

  return {
    isCartLoading,
    filteredCart,
    totalPrice,
  };
}
