//types
import type { ProfileFormType } from "../../../context/user-provider/UserProvider.types";

export const INIT_PROFILE_FORM: ProfileFormType = {
  username: "",
  avatar: "",
  email: "",
  activationCode: "",
  tradeUrl: "",
};
