import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

// icons
import { ArrowLeftIcon } from "../../../assets/icons/ArrowLeftIcon";

// consts
import { PATHS } from "../../../route/route.controls";

// context
import { blogContext } from "../../../context/blog-provider/BlogProvider";

// hooks
import { useArticleScreenFetch } from "./use-article";

// components
import Spinner from "../../../components/spinner/Spinner";
import Button from "../../../components/button/Button";

// styles
import styles from "./Article.screen.module.scss";

export function ArticleScreen() {
  const navigate = useNavigate();

  const { blogById } = useContext(blogContext);

  const { isArticleLoading } = useArticleScreenFetch();

  return (
    <div className={styles.articleSection}>
      <div className={styles.contentWrapper}>
        {isArticleLoading ? (
          <Spinner />
        ) : blogById ? (
          <>
            <div>
              <Button
                variant="outline"
                width="auto"
                icon={<ArrowLeftIcon />}
                iconOrientation="start"
                onClick={() => navigate(PATHS.blog)}
              >
                All Articles
              </Button>
            </div>
            <div className={styles.mainWrapper}>
              <div className={styles.titleWrapper}>
                <h1>{blogById.title}</h1>
              </div>

              <div
                className={styles.info}
                dangerouslySetInnerHTML={{ __html: blogById.content || "" }}
              />
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}
