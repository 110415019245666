import React, { useCallback, useContext, useState } from "react";

// components
import Spinner from "../../../components/spinner/Spinner";
import { Article } from "../../guest/main-screen/blog/article/Article";
import { ConfirmModal } from "../../../components/confirm-modal/ConfirmModal";
import Button from "../../../components/button/Button";
import { BlogManageModal } from "./blog-manage-modal/BlogManageModal";

// context
import { errorContext } from "../../../context/error-provider/ErrorProvider";
import { blogContext } from "../../../context/blog-provider/BlogProvider";

// hooks
import { useBlogManageFetch, useBlogManageForm } from "./use-blog-manage";

// types
import type { BlogType } from "../../../context/blog-provider/BlogProvider.types";

// styles
import styles from "./BlogManageScreen.module.scss";

export function BlogManageScreen() {
  const { error } = useContext(errorContext);
  const { blogsData } = useContext(blogContext);

  const { isBlogManageLoading } = useBlogManageFetch();
  const { handleRemoveBlog } = useBlogManageForm();

  const [isOpenBlogModal, setIsOpenBlogModal] = useState<
    "create" | "edit" | null
  >(null);
  const [isOpenRemovingBlogModal, setIsOpenRemovingBlogModal] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState<BlogType | null>(null);

  const handleOpenCreatingModal = useCallback(() => {
    setIsOpenBlogModal("create");
  }, [setIsOpenBlogModal]);

  const handleOpenEditingModal = (blog: BlogType) => {
    setIsOpenBlogModal("edit");
    setSelectedBlog(blog);
  };

  const handleOpenDeletingModal = (blog: BlogType) => {
    setIsOpenRemovingBlogModal(true);
    setSelectedBlog(blog);
  };

  const onDeleteBlog = useCallback(
    async (blog?: string) => {
      try {
        await handleRemoveBlog(blog);
      } catch (e) {
        error(e);
      } finally {
        setIsOpenRemovingBlogModal(false);
      }
    },
    [handleRemoveBlog, setIsOpenRemovingBlogModal, error]
  );

  if (isBlogManageLoading) {
    return (
      <div className={styles.spinner}>
        <Spinner color="white" />
      </div>
    );
  }

  return (
    <section className={styles.container}>
      <div className={styles.contentWrapper}>
        {blogsData && blogsData.length ? (
          blogsData.map((blog) => (
            <Article
              article={blog}
              openModal={handleOpenEditingModal}
              handleOpenDeletingModal={handleOpenDeletingModal}
            />
          ))
        ) : (
          <h4>Unfortunately, information about the blogs is not available.</h4>
        )}
      </div>

      <div className={styles.buttonWrapper}>
        <Button onClick={handleOpenCreatingModal} width="auto">
          Create new article
        </Button>
      </div>

      <BlogManageModal
        isOpen={isOpenBlogModal}
        onClose={() => setIsOpenBlogModal(null)}
        blog={selectedBlog}
      />

      <ConfirmModal
        isOpen={isOpenRemovingBlogModal}
        modalTitle="Delete product"
        modalText="Are you sure you want to delete this product?"
        onClose={() => setIsOpenRemovingBlogModal(false)}
        onSubmit={() => onDeleteBlog(selectedBlog?.id)}
      />
    </section>
  );
}
