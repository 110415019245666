import React, { SVGProps } from "react";

const UserIcon = ({
  className,
  color = "#FDFDFD",
  width = 24,
  height = 24,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 0C5.4 0 0 5.4 0 12C0 18.6 5.4 24 12 24C18.6 24 24 18.6 24 12C24 5.4 18.6 0 12 0ZM12 4C14.2 4 16 6.2 16 9C16 11.8 14.2 14 12 14C9.8 14 8 11.8 8 9C8 6.2 9.8 4 12 4ZM18.6 19.5C16.9 21 14.5 22 12 22C9.5 22 7.1 21 5.4 19.5C5 19.1 4.9 18.5 5.3 18.1C6.4 16.8 7.9 15.9 9.5 15.4C10.3 15.8 11.1 16 12 16C12.9 16 13.7 15.8 14.5 15.4C16.2 15.9 17.6 16.8 18.7 18.1C19.1 18.5 19.1 19.1 18.6 19.5Z"
        fill={color}
      />
    </svg>
  );
};
export { UserIcon };
