import { z } from "zod";

// schemas
import { orderSchema } from "../order-provider/OrderProvider.schemas";

export const signUpUserSchema = z.object({
  username: z.string(),
  password: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  phone: z.string(),
  avatar: z.string(),
});

export const logInUserSchema = z.object({
  password: z.string(),
  email: z.string(),
});

export const emailCodeSchema = z.object({
  activationCode: z.number(),
});

export const profileSchema = z.object({
  activationCode: z.string().nullable(),
  avatar: z.string().optional().nullable(),
  balance: z.string(),
  createdAt: z.string(),
  email: z.string().nullable(),
  firstName: z.string().nullable(),
  id: z.string(),
  isEmailConfirmed: z.boolean(),
  lastName: z.string().nullable(),
  orders: z.array(orderSchema),
  profileUrl: z.string(),
  role: z.enum(["user", "moderator", "admin"]),
  steamId: z.string(),
  tradeUrl: z.string(),
  updatedAt: z.string(),
  username: z.string(),
});

export const profileFormSchema = z.object({
  username: z.string().optional(),
  avatar: z.string().optional(),
  email: z.string(),
  activationCode: z.string(),
  tradeUrl: z.string().optional(),
});

export const allUsersSchema = z.object({
  total: z.number(),
  data: z.array(profileSchema),
});

export const changeUserRoleSchema = z.object({
  email_id: z.string(),
  role: z.enum(["admin", "user"]),
});

export const updateProfileSchema = profileFormSchema.pick({
  username: true,
  email: true,
  tradeUrl: true,
  avatar: true,
});

export const resetPasswordFormSchema = z.object({
  email: z.string().optional(),
  password: z.string().optional(),
  confirmPassword: z.string().optional(),
  ressetCode: z.number().optional(),
});

export const feedbackFormSchema = z.object({
  name: z.string(),
  last_name: z.string(),
  email: z.string(),
  phone: z.string(),
  company: z.string(),
  country: z.string(),
  message: z.string(),
});

export const steamRegistrationFormSchema = z.object({
  email: z.string(),
  code: z.string(),
  trade_link: z.string(),
});

export const steamAuthSchema = z.object({
  steamAuthUrl: z.string(),
});
