export const PATHS = {
  // general
  index: "/",
  skins: "skins",
  aboutUs: "aboutUs",
  blog: "/blog",
  article: "/blog/:articleId",
  cart: "/cart",
  cookie: "/cookie",
  privacyPolicy: "/privacy-policy",
  termsAndConditions: "/terms-and-conditions",
  refundPolicy: "/refund-policy",
  paymentFailed: "/payment/failed",
  paymentSuccess: "/payment/success",

  // user
  profile: "/profile",
  orders: "/orders",
  balance: "/balance",

  // moderator
  blogManage: "/blog-manage",

  // admin
  users: "/users",
} as const;

export const routeControlsList: Array<string> = Object.keys(PATHS);
