import React, { SVGProps } from "react";

const CheckIcon = ({
  className,
  color = "#699F4C",
  width = 18,
  height = 18,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 18C7.21997 18 5.47991 17.4722 3.99987 16.4832C2.51983 15.4943 1.36629 14.0887 0.685097 12.4442C0.00390887 10.7996 -0.174334 8.99001 0.172933 7.24419C0.5202 5.49836 1.37738 3.89472 2.63605 2.63604C3.89472 1.37737 5.49836 0.520205 7.24419 0.172938C8.99002 -0.174329 10.7996 0.00389535 12.4442 0.685084C14.0887 1.36627 15.4943 2.51983 16.4832 3.99987C17.4722 5.47991 18 7.21997 18 9C18 11.3869 17.0518 13.6761 15.364 15.364C13.6761 17.0518 11.387 18 9 18ZM3.9042 11.0295C3.71066 10.8338 3.60211 10.5697 3.60211 10.2945C3.60211 10.0193 3.71066 9.75517 3.9042 9.5595L4.43402 9.0327C4.52411 8.94179 4.63155 8.8699 4.74996 8.82131C4.86838 8.77272 4.99536 8.74842 5.12335 8.74984C5.25134 8.75127 5.37773 8.77839 5.49504 8.82961C5.61234 8.88083 5.71816 8.9551 5.80621 9.048L6.74221 10.0191C6.78683 10.0662 6.84059 10.1037 6.90019 10.1293C6.95979 10.155 7.02398 10.1682 7.08886 10.1682C7.15374 10.1682 7.21793 10.155 7.27753 10.1293C7.33713 10.1037 7.39087 10.0662 7.4355 10.0191L12.2055 5.1021C12.2942 5.00854 12.4009 4.93389 12.5192 4.88262C12.6374 4.83135 12.7649 4.80454 12.8938 4.80378C13.0227 4.80303 13.1504 4.82834 13.2693 4.87822C13.3882 4.92809 13.4957 5.0015 13.5855 5.09401L14.1 5.6142C14.2888 5.80907 14.3944 6.06976 14.3944 6.3411C14.3944 6.61244 14.2888 6.87313 14.1 7.068L7.7673 13.5033C7.67893 13.5948 7.57318 13.6677 7.45626 13.7178C7.33934 13.7678 7.21358 13.7941 7.08639 13.7949C6.9592 13.7957 6.83312 13.7712 6.71555 13.7226C6.59797 13.6741 6.49127 13.6026 6.4017 13.5123L3.9042 11.0295Z"
        fill={color}
      />
    </svg>
  );
};
export { CheckIcon };
