import React, { SVGProps } from "react";

const ArrowLeftIcon = ({
  className,
  color = "#fff",
  width = 21,
  height = 22,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 21 22"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.375 9.6875H5.775L8.80688 6.65563C9.06544 6.39706 9.1875 6.08862 9.1875 5.75C9.1875 5.10425 8.65397 4.4375 7.875 4.4375C7.52653 4.4375 7.22269 4.56416 6.96938 4.81812L1.74694 10.0406C1.53169 10.2558 1.3125 10.5216 1.3125 11C1.3125 11.4784 1.49559 11.7088 1.73644 11.9496L6.96938 17.1819C7.22269 17.4358 7.52653 17.5625 7.875 17.5625C8.65462 17.5625 9.1875 16.8958 9.1875 16.25C9.1875 15.9114 9.06544 15.6029 8.80688 15.3444L5.775 12.3125H18.375C19.0995 12.3125 19.6875 11.7245 19.6875 11C19.6875 10.2755 19.0995 9.6875 18.375 9.6875Z"
        fill={color}
      />
    </svg>
  );
};
export { ArrowLeftIcon };
