import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export function useScrollIntoView() {
  const skinsRef = useRef<HTMLDivElement | null>(null);
  const aboutUsRef = useRef<HTMLDivElement | null>(null);

  const location = useLocation();

  const scrollToSection = (section: string) => {
    if (section !== "skins" && section !== "aboutUs") return;

    setTimeout(() => {
      if (section === "skins" && skinsRef.current) {
        window.scrollTo({
          top: skinsRef.current.offsetTop,
          behavior: "smooth",
        });
      }

      if (section === "aboutUs" && aboutUsRef.current) {
        aboutUsRef.current.scrollIntoView({
          behavior: "smooth",
        });
      }
    }, 0); // The minimal delay allows the DOM to finish rendering before the scroll action is triggered.
  };

  useEffect(() => {
    scrollToSection(location.pathname.substring(1));
  }, [location]);

  return { skinsRef, aboutUsRef, scrollToSection };
}
