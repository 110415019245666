import { useCallback, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// components
import Button from "../../../../components/button/Button";
import Checkbox from "../../../../components/checkbox/Checkbox";
import { SteamModal } from "../../../../components/steam-modal/SteamModal";
import { SteamRegistrationModal } from "../../../../components/steam-registration-modal/SteamRegistrationModal";

// context
import { errorContext } from "../../../../context/error-provider/ErrorProvider";
import { currencyContext } from "../../../../context/currency-provider/CurrencyProvider";
import { userContext } from "../../../../context/user-provider/UserProvider";
import { orderContext } from "../../../../context/order-provider/OrderProvider";
import { cartContext } from "../../../../context/cart-provider/CartProvider";

// consts
import { PATHS } from "../../../../route/route.controls";

// styles
import styles from "./OrderSummary.module.scss";

type OrderSummaryProps = {
  items: number;
  total: string;
  skinIds: string[];
};

export function OrderSummary({ items, total, skinIds }: OrderSummaryProps) {
  const { error, success } = useContext(errorContext);
  const { getCurrencySymbol } = useContext(currencyContext);
  const { userData } = useContext(userContext);
  const { createOrder } = useContext(orderContext);
  const { clearCart } = useContext(cartContext);

  const navigate = useNavigate();

  const [isChecked, setIsChecked] = useState(false);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenRegistrationModal, setIsOpenRegistrationModal] = useState(false);

  const handleCheckout = useCallback(async () => {
    try {
      await createOrder(skinIds, Number(total));

      clearCart();
    } catch (e) {
      error(e);
    }
  }, [total]);

  const buttonText = () => {
    if (userData && Number(userData.balance) < Number(total)) {
      return "Top Up the Balance";
    }

    if (!userData) {
      return "Please first log in";
    }

    return "Checkout";
  };

  const handleButtonClick = useCallback(async () => {
    try {
      if (!userData) {
        return setIsOpenModal(true);
      }

      if (userData && Number(userData.balance) < Number(total)) {
        return navigate(PATHS.balance);
      }

      if (userData && !userData.tradeUrl) {
        return setIsOpenRegistrationModal(true);
      }

      await handleCheckout();
      success("Thank you! Your order was successful.");
    } catch (e) {
      error(
        "Payment failed. Please verify your information and try once more."
      );
    }
  }, [userData, PATHS, navigate, setIsOpenModal, handleCheckout]);

  return (
    <div className={styles.container}>
      <p>Order Summary</p>
      <div className={styles.subtotalWrapper}>
        <p className={styles.subtotalText}>Subtotal ({items} items):</p>
        <p className={styles.subTotalPrice}>
          {getCurrencySymbol()} {total}
        </p>
      </div>
      <div className={styles.checkboxWrapper}>
        <Checkbox
          id="terms_and_conditions"
          name="terms"
          value="1"
          checked={isChecked}
          onChange={() => setIsChecked((prev) => !prev)}
        />

        <p className={styles.checkboxText}>
          I have read and agree with{" "}
          <Link to={PATHS.termsAndConditions}>Terms of service</Link> and{" "}
          <Link to={PATHS.privacyPolicy}>Privacy policy</Link>
        </p>
      </div>
      <div className={styles.divider}></div>
      <div className={styles.totalWrapper}>
        <p>Total</p>
        <p>
          {getCurrencySymbol()} {total}
        </p>
      </div>
      <div>
        {userData && Number(userData.balance) < Number(total) ? (
          <p className={styles.warningMessage}>
            You don't have enough funds in your account. Continue checkout after
            topping up your balance
          </p>
        ) : null}
      </div>
      <Button
        variant="secondary"
        onClick={handleButtonClick}
        disabled={!isChecked}
      >
        {buttonText()}
      </Button>

      <SteamModal isOpen={isOpenModal} onClose={() => setIsOpenModal(false)} />

      <SteamRegistrationModal
        isOpen={isOpenRegistrationModal}
        onClose={() => setIsOpenRegistrationModal(false)}
      />
    </div>
  );
}
