// hooks
import { useBlogScreenFetch } from "./use-blog-screen";

// images
import mainCircles from "../../../assets/images/main_circles.png";

// components
import Spinner from "../../../components/spinner/Spinner";
import { BlogRecomendations } from "./blog-recomedations/BlogRecomendations";
import { Articles } from "./articles/Articles";

// styles
import styles from "./Blog.screen.module.scss";

export function BlogScreen() {
  const { isBlogsLoading } = useBlogScreenFetch();

  return (
    // TODO TEMPORARY SOLUTION FOR THIS BACKGROUND
    <div
      className={styles.container}
      style={{ backgroundImage: `url(${mainCircles})` }}
    >
      {isBlogsLoading ? (
        <Spinner />
      ) : (
        <>
          <BlogRecomendations />

          <Articles />
        </>
      )}
    </div>
  );
}
