import React, { useContext } from "react";

// carousel
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

// components
import { BlogRecomendationItem } from "../blog-recomendation-item/BlogRecomendationItem";

// context
import { blogContext } from "../../../../context/blog-provider/BlogProvider";

// styles
import styles from "./BlogRecomendations.module.scss";

export function BlogRecomendations() {
  const { blogsData } = useContext(blogContext);

  return (
    <section>
      <div className={styles.contentWrapper}>
        <Carousel
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          infiniteLoop
          autoPlay
          className={styles.carousel}
        >
          {blogsData?.map((recomendation, index) => (
            <BlogRecomendationItem key={index} recomendation={recomendation} />
          ))}
        </Carousel>
      </div>
    </section>
  );
}
