import React from "react";

// types
import type { AboutUsItemType } from "../AboutUs.types";

// styles
import styles from "./AboutUsItem.module.scss";

type AboutUsItemProps = {
  aboutUsItem: AboutUsItemType;
};

export function AboutUsItem({ aboutUsItem }: AboutUsItemProps) {
  return (
    <div className={styles.aboutUsItem}>
      <img src={aboutUsItem.img} alt={aboutUsItem.img} />
      <h4>{aboutUsItem.title}</h4>
      <p>{aboutUsItem.description}</p>
    </div>
  );
}
