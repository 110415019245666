import React, { SVGProps } from "react";

const StarIcon = ({
  className,
  color = "#D9D9D9",
  width = 13,
  height = 13,
  ...props
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 13 13"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.5 0.5L7.84708 4.99139H12.2063L8.67963 7.76722L10.0267 12.2586L6.5 9.48278L2.97329 12.2586L4.32037 7.76722L0.793661 4.99139H5.15292L6.5 0.5Z"
        fill={color}
        fillOpacity="0.2"
      />
    </svg>
  );
};
export { StarIcon };
