import { useCallback, useContext, useEffect, useMemo, useState } from "react";

// context
import { blogContext } from "../../../context/blog-provider/BlogProvider";
import { errorContext } from "../../../context/error-provider/ErrorProvider";

// types
import type {
  BlogFormType,
  BlogType,
} from "../../../context/blog-provider/BlogProvider.types";

// consts
import { INIT_BLOG_FORM_DATA } from "./BlogManageScreen.consts";

export function useBlogManageForm(
  modalType?: "create" | "edit" | null,
  blogManage?: BlogType | null
) {
  const { error, success } = useContext(errorContext);
  const { createBlog, editBlog, deleteBlog } = useContext(blogContext);

  const [blogManageFormData, setBlogManageFormData] =
    useState<BlogFormType>(INIT_BLOG_FORM_DATA);

  useEffect(() => {
    if (blogManage && modalType === "edit") {
      const { title, content } = blogManage;

      setBlogManageFormData({
        title,
        content,
      });
    } else {
      setBlogManageFormData(INIT_BLOG_FORM_DATA);
    }
  }, [blogManage, modalType]);

  const handleChangeBlogManageData = useCallback(
    (
      e:
        | React.ChangeEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
      const { name, value } = e.target;

      setBlogManageFormData((prev) => ({ ...prev, [name]: value }));
    },
    [setBlogManageFormData, error]
  );

  const handleChangeEditorData = useCallback(
    (text: string) => {
      setBlogManageFormData((prev) => ({ ...prev, content: text }));
    },
    [setBlogManageFormData, error]
  );

  const onSubmit = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      try {
        // edit
        if (blogManage && modalType === "edit") {
          await editBlog(blogManage.id, blogManageFormData);
          return success("Blog successfully edited.");
        }

        // create
        await createBlog(blogManageFormData);
        success("Blog successfully created.");
      } catch (e) {
        throw e;
      }
    },
    [blogManageFormData, modalType, blogManage, createBlog, editBlog, success]
  );

  const handleRemoveBlog = useCallback(
    async (blogId?: string) => {
      try {
        if (blogId) {
          await deleteBlog(blogId);
          success("The book has been successfully deleted");
        }
      } catch (e) {
        throw e;
      }
    },
    [deleteBlog, success]
  );

  const isDisabledSubmitButton = useMemo(
    () => !blogManageFormData.title || !blogManageFormData.content,
    [blogManageFormData]
  );

  return {
    blogManageFormData,
    isDisabledSubmitButton,
    handleChangeBlogManageData,
    handleChangeEditorData,
    onSubmit,
    handleRemoveBlog,
  };
}

export function useBlogManageFetch() {
  const { error } = useContext(errorContext);
  const { getBlogs } = useContext(blogContext);

  const [isBlogManageLoading, setIsBlogManageLoading] = useState(false);

  const blogManageFetch = async () => {
    try {
      setIsBlogManageLoading(true);

      await getBlogs();
    } catch (err) {
      error(err);
    } finally {
      setIsBlogManageLoading(false);
    }
  };

  useEffect(() => {
    blogManageFetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isBlogManageLoading };
}
